// import React, { useEffect, useState } from "react";
// import { Link } from "react-router-dom";
// import Topheader from "./Topheader";
// import { GiHamburgerMenu } from "react-icons/gi";
// import '../css/style.css';


// import { faVideo } from '@fortawesome/free-solid-svg-icons';
// import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';


// const Navbar = () => {
   
//     const [isMobileView, setIsMobileView] = useState(false);
//     const [isNavbarOpen, setIsNavbarOpen] = useState(false);
//     useEffect(() => {
//         function handleResize() {
//             setIsMobileView(window.innerWidth <= 992);
//         }

//         handleResize();
//         window.addEventListener("resize", handleResize);

//         return () => {
//             window.removeEventListener("resize", handleResize);
//         };
//     }, []);

//     const handleNavLinkClick = () => {
//         if (isMobileView) {
//             setIsNavbarOpen(!isNavbarOpen);
//             document.getElementById("navbarCollapse").classList.remove("show");
//         }
//     };

//     const iconStyle = {
//         marginRight: '10px',
//       };

//     return (
//         <>
//             <Topheader />
//             {/* <div className={`navbar navbar-expand-lg ${isMobileView ? 'bg-dark' : 'bg-light'} navbar-dark`}> */}
//             <div className={`navbar navbar-expand-lg  navbar-dark`}>
//                 <div className="container-fluid ">
//                     <img src="./LOGO999.png" height={'60px'} className="resposnvie_logo"  />
//                     {/* <a href="/" className="navbar-brand"  style={{ fontSize: '38px' }}>A<span>nanta</span>S<span>aukhyam</span></a> */}
//                     <button type="button" className="navbar-toggler  ml-auto" data-toggle="collapse" data-target="#navbarCollapse" style={{width:"30px" ,height:"30px"}}>
//                         {/* <span className="navbar-toggler-icon"    style={{width:"18px",transform: "translate(-7px, -6px)"}}></span> */}
//                         <GiHamburgerMenu  style={{width:"18px",transform: "translate(-7px, -6px)",color:"black"}}/>
                        
//                     </button>
                    

//                     <div className={`collapse navbar-collapse`} id="navbarCollapse">
//                         <div className="navbar-nav ms-auto py-0 ml-auto ">
//                             <Link to="/" className="nav-item nav-link " style={{ color: "black",fontWeight: "bold",marginLeft:'-680px'  }} onClick={handleNavLinkClick}>Home</Link>
//                             <Link to="/about" className="nav-item nav-link" style={{ color: "black", fontWeight: "bold" }} onClick={handleNavLinkClick}>About</Link>

//                             <div className="nav-item dropdown">
//                                 <Link to="#" className="nav-link dropdown-toggle" data-toggle="dropdown" style={{ fontWeight: "bold",color:'black' }} onClick={handleNavLinkClick}>Services</Link>
//                                 <div className="dropdown-menu">
//                                     <Link to="/yoga" className="dropdown-item" style={{ color: "black", fontWeight: "bold" }} onClick={handleNavLinkClick}>Yoga</Link>


//                                     <Link to='/consultation' className="dropdown-item" style={{ color: "black", fontWeight: "bold" }}onClick={handleNavLinkClick}>Consultation</Link>
//                                     <Link to='/heathylifestyle' className="dropdown-item" style={{ color: "black", fontWeight: "bold" }}onClick={handleNavLinkClick}>Healthy LifeStyle</Link>
//                                     <Link to='/diet' className="dropdown-item" style={{ color: "black", fontWeight: "bold" }}onClick={handleNavLinkClick}>Diet</Link>
//                                     <Link to='/acupenture' className="dropdown-item" style={{ color: "black", fontWeight: "bold" }}onClick={handleNavLinkClick}>Acupuncture</Link>
//                                     <Link to='/counselleing' className="dropdown-item" style={{ color: "black", fontWeight: "bold" }}onClick={handleNavLinkClick}>Counseling</Link>
//                                     <Link to='/trainingyoga' className="dropdown-item" style={{ color: "black", fontWeight: "bold" }} onClick={handleNavLinkClick}>Trainings on Yoga</Link>
//                                     <Link to='/seminars' className="dropdown-item" style={{ color: "black", fontWeight: "bold" }}onClick={handleNavLinkClick}>Seminars to Corporate</Link>
//                                     <Link to='/stressdeletion' className="dropdown-item" style={{ color: "black", fontWeight: "bold" }} onClick={handleNavLinkClick}>Stress Deletion</Link>
//                                     <Link to='/naturopathy' className="dropdown-item" style={{ color: "black", fontWeight: "bold" }} onClick={handleNavLinkClick}>Naturopathy</Link>
//                                     <Link to='/CorporateClinic' className="dropdown-item" style={{ color: "black", fontWeight: "bold" }} onClick={handleNavLinkClick}>Corporate Clinic</Link>



//                                 </div>
//                             </div>
//                             {/* <Link to="/price" className="nav-item nav-link" style={{color:"black" ,fontWeight: "bold"}}>Price</Link> */}
//                             <Link to="/gallery" className="nav-item nav-link" style={{ color: "black", fontWeight: "bold" }}onClick={handleNavLinkClick}>Gallery</Link>
//                             {/* <Link to="/team" className="nav-item nav-link" style={{color:"black" ,fontWeight: "bold"}}>Team</Link> */}
//                             {/* <Link to="/pose" className="nav-item nav-link"style={{color:"black" ,fontWeight: "bold"}}>Pose</Link> */}
//                             {/* <Link to="blogs" className="nav-item nav-link">Blogs</Link> */}
//                             {/* <Link to="/contact" className="nav-item nav-link" style={{color:"black" ,fontWeight: "bold"}}>Contact</Link> */}
//                             <div className="nav-item ">
//                                 <Link to="/BlogDetails" className="nav-link " style={{ color: "black", fontWeight: "bold" }}onClick={handleNavLinkClick}>Blog</Link>
//                                 {/* <div className="dropdown-menu"> 
//                             <Link to='/bloggrid' className="dropdown-item" style={{color:"black" ,fontWeight: "bold"}}>Blog Grid</Link>
//                             <Link to='/blogdetails'  className="dropdown-item" style={{color:"black" ,fontWeight: "bold"}}>Blog Detail</Link>
                           
//                             </div> */}
//                             </div>
//                             {/* ... other navigation links ... */}
//                             <Link to="/contact" className="nav-item nav-link" style={{ color: "black", fontWeight: "bold" }} onClick={handleNavLinkClick}>Contact</Link>
//                             <Link to="/Videoconsultancy">
                        
//                         <button className="learm_more_button1" style={{fontFamily: "Open Sans,Sans Serif",marginTop:'1px'}}>
//                         <FontAwesomeIcon icon={faVideo} style={iconStyle} />  Video Consultation<span style={{color:"white",fontSize:'15px'}}>*</span>
//                         </button>
//                         </Link>
//                         </div>
//                     </div>
//                 </div>
//             </div>
//         </>
//     );
// };

// export default Navbar;





// import React, { useEffect, useState } from "react";
// import { Link } from "react-router-dom";
// import Topheader from "./Topheader";
// import { GiHamburgerMenu } from "react-icons/gi";
// import { faVideo } from '@fortawesome/free-solid-svg-icons';
// import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

// const Navbar = () => {
//     const [isMobileView, setIsMobileView] = useState(false);
//     const [isNavbarOpen, setIsNavbarOpen] = useState(false);

//     useEffect(() => {
//         function handleResize() {
//             setIsMobileView(window.innerWidth <= 992);
//         }

//         handleResize();
//         window.addEventListener("resize", handleResize);

//         return () => {
//             window.removeEventListener("resize", handleResize);
//         };
//     }, []);

//     const handleNavLinkClick = () => {
//         if (isMobileView) {
//             setIsNavbarOpen(!isNavbarOpen);
//             document.getElementById("navbarCollapse").classList.remove("show");
//         }
//     };

//     const iconStyle = {
//         marginRight: '10px',
//     };

//     const buttonStyle = {
//         fontFamily: "Open Sans, Sans Serif",
//         marginTop: '1px',
//     };

//     const desktopOnlyStyle = {
//         display: isMobileView ? 'none' : 'inline-block',
//     };

//     const navbarStyle = {
//         width: "30px",
//         height: "30px",
//     };

//     const hamburgerIconStyle = {
//         width: "18px",
//         transform: "translate(-7px, -6px)",
//         color: "black",
//     };

//     const navLinkStyle = {
//         color: "black",
//         fontWeight: "bold",
//     };

//     return (
//         <>
//             <Topheader />
//             <div className={`navbar navbar-expand-lg navbar-dark`}>
//                 <div className="container-fluid">
//                     <img src="./LOGO999.png" height={'60px'} className="resposnvie_logo" alt="Logo" />
//                     <button type="button" className="navbar-toggler ml-auto" data-toggle="collapse" data-target="#navbarCollapse" style={navbarStyle}>
//                         <GiHamburgerMenu style={hamburgerIconStyle} />
//                     </button>

//                     <div className={`collapse navbar-collapse`} id="navbarCollapse">
//                         <div className="navbar-nav ms-auto py-0 ml-auto">
//                             {/* <Link to="/" className="nav-item nav-link" style={{ ...navLinkStyle, marginLeft: '-680px',color:'black' }} onClick={handleNavLinkClick}>Home</Link> */}
//                             <Link to="/" className="nav-item nav-link" style={navLinkStyle} onClick={handleNavLinkClick}>Home</Link>
//                             <Link to="/about" className="nav-item nav-link" style={navLinkStyle} onClick={handleNavLinkClick}>About</Link>

//                             <div className="nav-item dropdown">
//                                 <Link to="#" className="nav-link dropdown-toggle" data-toggle="dropdown" style={navLinkStyle} onClick={handleNavLinkClick}>Services</Link>
//                                 <div className="dropdown-menu">
//                                     <Link to="/yoga" className="dropdown-item" style={navLinkStyle} onClick={handleNavLinkClick}>Yoga</Link>
//                                     <Link to='/consultation' className="dropdown-item" style={navLinkStyle} onClick={handleNavLinkClick}>Consultation</Link>
//                                     <Link to='/heathylifestyle' className="dropdown-item" style={navLinkStyle} onClick={handleNavLinkClick}>Healthy LifeStyle</Link>
//                                     <Link to='/diet' className="dropdown-item" style={navLinkStyle} onClick={handleNavLinkClick}>Diet</Link>
//                                     <Link to='/acupenture' className="dropdown-item" style={navLinkStyle} onClick={handleNavLinkClick}>Acupuncture</Link>
//                                     <Link to='/counselleing' className="dropdown-item" style={navLinkStyle} onClick={handleNavLinkClick}>Counseling</Link>
//                                     <Link to='/trainingyoga' className="dropdown-item" style={navLinkStyle} onClick={handleNavLinkClick}>Trainings on Yoga</Link>
//                                     <Link to='/seminars' className="dropdown-item" style={navLinkStyle} onClick={handleNavLinkClick}>Seminars to Corporate</Link>
//                                     <Link to='/stressdeletion' className="dropdown-item" style={navLinkStyle} onClick={handleNavLinkClick}>Stress Deletion</Link>
//                                     <Link to='/naturopathy' className="dropdown-item" style={navLinkStyle} onClick={handleNavLinkClick}>Naturopathy</Link>
//                                     <Link to='/CorporateClinic' className="dropdown-item" style={navLinkStyle} onClick={handleNavLinkClick}>Corporate Clinic</Link>
//                                 </div>
//                             </div>

//                             <Link to="/gallery" className="nav-item nav-link" style={navLinkStyle} onClick={handleNavLinkClick}>Gallery</Link>

//                             <div className="nav-item">
//                                 <Link to="/BlogDetails" className="nav-link" style={navLinkStyle} onClick={handleNavLinkClick}>Blog</Link>
//                             </div>

//                             <Link to="/contact" className="nav-item nav-link" style={navLinkStyle} onClick={handleNavLinkClick}>Contact</Link>

//                             <Link to="/Videoconsultancy" style={desktopOnlyStyle}>
//                                 <button className="learm_more_button1" style={buttonStyle}>
//                                     <FontAwesomeIcon icon={faVideo} style={iconStyle} /> Video Consultation<span style={{ color: "white", fontSize: '15px' }}>*</span>
//                                 </button>
//                             </Link>
//                         </div>
//                     </div>
//                 </div>
//             </div>
//         </>
//     );
// };

// export default Navbar;

// import React, { useEffect, useState } from "react";
// import { Link } from "react-router-dom";
// import Topheader from "./Topheader";
// import { GiHamburgerMenu } from "react-icons/gi";
// import { FaTimes } from "react-icons/fa";
// import { faVideo } from '@fortawesome/free-solid-svg-icons';
// import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

// const Navbar = () => {
//     const [isMobileView, setIsMobileView] = useState(false);
//     const [isNavbarOpen, setIsNavbarOpen] = useState(false);

//     useEffect(() => {
//         function handleResize() {
//             setIsMobileView(window.innerWidth <= 992);
//         }

//         handleResize();
//         window.addEventListener("resize", handleResize);

//         return () => {
//             window.removeEventListener("resize", handleResize);
//         };
//     }, []);

//     const handleNavLinkClick = () => {
//         if (isMobileView) {
//             setIsNavbarOpen(!isNavbarOpen);
//             document.getElementById("navbarCollapse").classList.remove("show");
//         }
//     };

//     const iconStyle = {
//         marginRight: '10px',
//     };

//     const buttonStyle = {
//         fontFamily: "Open Sans, Sans Serif",
//         marginTop: '1px',
//     };

//     const desktopOnlyStyle = {
//         display: isMobileView ? 'none' : 'inline-block',
//     };

//     const navbarStyle = {
//         width: "30px",
//         height: "30px",
//     };

//     const hamburgerIconStyle = {
//         width: "18px",
//         transform: "translate(-7px, -6px)",
//         color: "black",
      
//     };

//     const navLinkStyle = {
//         color: "black",
//         fontWeight: "bold",
//     };

//     return (
//         <>
//             <Topheader />
//             <div className={`navbar navbar-expand-lg navbar-dark`}>
//                 <div className="container-fluid">
//                     <img src="./LOGO999.png" height={'60px'} className="resposnvie_logo" alt="Logo" />
//                     <button type="button" className="navbar-toggler ml-auto" data-toggle="collapse" data-target="#navbarCollapse" onClick={handleNavLinkClick} style={navbarStyle}>
//                         {isNavbarOpen ? <FaTimes style={hamburgerIconStyle} /> : <GiHamburgerMenu style={hamburgerIconStyle} />}
//                     </button>

//                     <div className={`collapse navbar-collapse ${isNavbarOpen ? 'show' : ''}`} id="navbarCollapse">
//                         <div className="navbar-nav ms-auto py-0 ml-auto">
//                             <Link to="/" className="nav-item nav-link" style={navLinkStyle} onClick={handleNavLinkClick}>Home</Link>
//                             <Link to="/about" className="nav-item nav-link" style={navLinkStyle} onClick={handleNavLinkClick}>About</Link>

//                             <div className="nav-item dropdown">
//                                 <Link to="#" className="nav-link dropdown-toggle" data-toggle="dropdown" style={navLinkStyle} onClick={handleNavLinkClick}>Services</Link>
//                                 <div className="dropdown-menu">
//                                     <Link to="/yoga" className="dropdown-item" style={navLinkStyle} onClick={handleNavLinkClick}>Yoga</Link>
//                                     <Link to='/consultation' className="dropdown-item" style={navLinkStyle} onClick={handleNavLinkClick}>Consultation</Link>
//                                     <Link to='/heathylifestyle' className="dropdown-item" style={navLinkStyle} onClick={handleNavLinkClick}>Healthy LifeStyle</Link>
//                                     <Link to='/diet' className="dropdown-item" style={navLinkStyle} onClick={handleNavLinkClick}>Diet</Link>
//                                     <Link to='/acupenture' className="dropdown-item" style={navLinkStyle} onClick={handleNavLinkClick}>Acupuncture</Link>
//                                     <Link to='/counselleing' className="dropdown-item" style={navLinkStyle} onClick={handleNavLinkClick}>Counseling</Link>
//                                     <Link to='/trainingyoga' className="dropdown-item" style={navLinkStyle} onClick={handleNavLinkClick}>Trainings on Yoga</Link>
//                                     <Link to='/seminars' className="dropdown-item" style={navLinkStyle} onClick={handleNavLinkClick}>Seminars to Corporate</Link>
//                                     <Link to='/stressdeletion' className="dropdown-item" style={navLinkStyle} onClick={handleNavLinkClick}>Stress Deletion</Link>
//                                     <Link to='/naturopathy' className="dropdown-item" style={navLinkStyle} onClick={handleNavLinkClick}>Naturopathy</Link>
//                                     <Link to='/CorporateClinic' className="dropdown-item" style={navLinkStyle} onClick={handleNavLinkClick}>Corporate Clinic</Link>
//                                 </div>
//                             </div>

//                             <Link to="/gallery" className="nav-item nav-link" style={navLinkStyle} onClick={handleNavLinkClick}>Gallery</Link>

//                             <div className="nav-item">
//                                 <Link to="/BlogDetails" className="nav-link" style={navLinkStyle} onClick={handleNavLinkClick}>Blog</Link>
//                             </div>

//                             <Link to="/contact" className="nav-item nav-link" style={navLinkStyle} onClick={handleNavLinkClick}>Contact</Link>

//                             <Link to="/Videoconsultancy" style={desktopOnlyStyle}>
//                                 <button className="learm_more_button1" style={buttonStyle}>
//                                     <FontAwesomeIcon icon={faVideo} style={iconStyle} /> Video Consultation<span style={{ color: "white", fontSize: '15px' }}>*</span>
//                                 </button>
//                             </Link>
//                         </div>
//                     </div>
//                 </div>
//             </div>
//         </>
//     );
// };

// export default Navbar;


import React, { useEffect, useState } from "react";
import { Link } from "react-router-dom";
import Topheader from "./Topheader";
import { GiHamburgerMenu } from "react-icons/gi";
import { FaTimes } from "react-icons/fa";
import { faVideo } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

const Navbar = () => {
    const [isMobileView, setIsMobileView] = useState(false);
    const [isNavbarOpen, setIsNavbarOpen] = useState(false);

    useEffect(() => {
        function handleResize() {
            setIsMobileView(window.innerWidth <= 992);
        }

        handleResize();
        window.addEventListener("resize", handleResize);

        return () => {
            window.removeEventListener("resize", handleResize);
        };
    }, []);

    const handleNavLinkClick = () => {
        if (isMobileView) {
            setIsNavbarOpen(!isNavbarOpen);
        }
    };

    const iconStyle = {
        marginRight: '10px',
    };

    const buttonStyle = {
        fontFamily: "Open Sans, Sans Serif",
        marginTop: '1px',
    };

    const desktopOnlyStyle = {
        display: isMobileView ? 'none' : 'inline-block',
    };

    const navbarStyle = {
        width: "30px",
        height: "30px",
    };

    const hamburgerIconStyle = {
        width: "18px",
        transform: "translate(-7px, -6px)",
        color: "black",
    };

    const navLinkStyle = {
        color: "black",
        fontWeight: "bold",
        // Added background color for mobile view
        backgroundColor: isMobileView ? 'white' : 'transparent',
        padding: isMobileView ? '10px 15px' : '0.5rem 1rem', // Adjust padding for better touch target in mobile
    };

    return (
        <>
            <Topheader />
            <div className={`navbar navbar-expand-lg navbar-dark`}>
                <div className="container-fluid">
                    <img src="./LOGO999.png" height={'60px'} className="resposnvie_logo" alt="Logo" />
                    <button
                        type="button"
                        className="navbar-toggler ml-auto"
                        data-toggle="collapse"
                        data-target="#navbarCollapse"
                        onClick={() => setIsNavbarOpen(!isNavbarOpen)}
                        style={navbarStyle}
                    >
                        {isNavbarOpen ? <FaTimes style={hamburgerIconStyle} /> : <GiHamburgerMenu style={hamburgerIconStyle} />}
                    </button>

                    <div className={`collapse navbar-collapse ${isNavbarOpen ? 'show' : ''}`} id="navbarCollapse">
                        <div className="navbar-nav ms-auto py-0 ml-auto">
                            <Link to="/" className="nav-item nav-link" style={navLinkStyle} onClick={handleNavLinkClick}>Home</Link>
                            <Link to="/about" className="nav-item nav-link" style={navLinkStyle} onClick={handleNavLinkClick}>About</Link>

                            <div className="nav-item dropdown">
                                <Link to="#" className="nav-link dropdown-toggle" data-toggle="dropdown" style={navLinkStyle} onClick={handleNavLinkClick}>Services</Link>
                                <div className="dropdown-menu">
                                    <Link to="/yoga" className="dropdown-item" style={navLinkStyle} onClick={handleNavLinkClick}>Yoga</Link>
                                    <Link to='/consultation' className="dropdown-item" style={navLinkStyle} onClick={handleNavLinkClick}>Consultation</Link>
                                    <Link to='/heathylifestyle' className="dropdown-item" style={navLinkStyle} onClick={handleNavLinkClick}>Healthy LifeStyle</Link>
                                    <Link to='/diet' className="dropdown-item" style={navLinkStyle} onClick={handleNavLinkClick}>Diet</Link>
                                    <Link to='/acupenture' className="dropdown-item" style={navLinkStyle} onClick={handleNavLinkClick}>Acupuncture</Link>
                                    <Link to='/counselleing' className="dropdown-item" style={navLinkStyle} onClick={handleNavLinkClick}>Counseling</Link>
                                    <Link to='/trainingyoga' className="dropdown-item" style={navLinkStyle} onClick={handleNavLinkClick}>Trainings on Yoga</Link>
                                    <Link to='/seminars' className="dropdown-item" style={navLinkStyle} onClick={handleNavLinkClick}>Seminars to Corporate</Link>
                                    <Link to='/stressdeletion' className="dropdown-item" style={navLinkStyle} onClick={handleNavLinkClick}>Stress Deletion</Link>
                                    <Link to='/naturopathy' className="dropdown-item" style={navLinkStyle} onClick={handleNavLinkClick}>Naturopathy</Link>
                                    <Link to='/CorporateClinic' className="dropdown-item" style={navLinkStyle} onClick={handleNavLinkClick}>Corporate Clinic</Link>
                                </div>
                            </div>

                            <Link to="/gallery" className="nav-item nav-link" style={navLinkStyle} onClick={handleNavLinkClick}>Gallery</Link>

                            <div className="nav-item">
                                <Link to="/BlogDetails" className="nav-link" style={navLinkStyle} onClick={handleNavLinkClick}>Blog</Link>
                            </div>

                            <Link to="/contact" className="nav-item nav-link" style={navLinkStyle} onClick={handleNavLinkClick}>Contact</Link>

                            <Link to="/Videoconsultancy" style={desktopOnlyStyle}>
                                <button className="learm_more_button1" style={buttonStyle}>
                                    <FontAwesomeIcon icon={faVideo} style={iconStyle} /> Video Consultation<span style={{ color: "white", fontSize: '15px' }}>*</span>
                                </button>
                            </Link>
                        </div>
                    </div>
                </div>
            </div>
        </>
    );
};

export default Navbar;
