import React,{useEffect} from 'react'
import { MdKeyboardDoubleArrowRight } from "react-icons/md";

const Counselleing = () => {
  useEffect(() => {
    window.scrollTo(0, 0); // Scrolls to the top of the page
}, []);

  return (

    <div>
      <div className="page-headercou" >
        <div className="container resposnive_breadmcrum">
          <div className="row">
            <div className="col-12" style={{fontFamily:"Circular"}}>
              <h2 >Counseling</h2>
            </div>
            <div className="col-12" style={{fontFamily:"Circular"}}>
              <a href="/" >Home</a>
              <a href="" ><MdKeyboardDoubleArrowRight/>Counseling </a>
            </div>
          </div>
        </div>
      </div>

      
      <div className="about wow fadeInUp" data-wow-delay="0.1s"  style={{marginTop:'-10px'}}>
        <div className="container">
          <div className="row align-items-center">
            <div className="col-lg-6 col-md-6">
              <div >
                <p><br /><br /></p>

              </div>
              <div className=" about wow fadeInUp counselleing" data-wow-delay="0.3s" >
              <img src="img1/councelling.jpg" alt="about" className="move-image counselleing"  />
              </div>
            </div>
            <div className="col-lg-6 col-md-6" >
              <div className="section-header text-left">
                <p>Counseling</p>
                <h2>We Hear You</h2>

              </div>
              <div className="about-text about wow fadeInUp" data-wow-delay="0.6s" style={{marginTop:'-40px'}}>
                   <p>


                  At <b >AnantaSaukhyam</b>, we understand that maintaining mental and emotional well-being is just as important as physical health. Our  Counseling services are designed to provide you with a safe and supportive environment where you can explore your thoughts, emotions, and life challenges while integrating the principles of yoga for holistic healing.</p>
                  <p>
                 <b>Counseling Sessions are designed to understand the  Individual Nature ( Swabhava).</b>  Helps them to understand there Nature, qualities & remove the myths of Mind. Develop a balanced Emotional well-being & incorporate it in Day to day life to reach  their highest potential.
                 

                     <br /> <br />
                     <p>
                  <b>At AnantaSaukhyam, we specifically work on concept  reboot, restart, uninstall & install when we are stuck with our past emotions where moving ahead just looks impossible we hear you & help you to reach you're Best of Yourself .</b> 
                  </p>
                     <p>
                  <b>1. Holistic Approach:</b> Our counseling sessions integrate traditional counseling techniques with yoga philosophy, breathwork, meditation, and mindfulness practices, offering a holistic approach to healing.
                  </p><p>
                  <b> 2. Experienced Counselors:</b> Our certified counselors have extensive experience in psychology and yoga, ensuring that you receive expert guidance and support.
                  </p><p>
                  <b>3. Tailored Sessions:</b> We understand that every individual is unique. Our counseling sessions are personalized to address your specific needs, challenges, and goals.
                  </p>
                 
                  </p>
                  {/* 4. Stress Management: Learn practical stress management techniques from yoga that can be applied in your daily life to reduce anxiety and promote relaxation.
5. Emotional Resilience: Develop emotional resilience and coping skills that empower you to navigate life's ups and downs with grace and self-compassion.
6. Mindfulness and Meditation: Explore mindfulness and meditation practices that enhance self-awareness, improve focus, and bring a sense of inner peace. */}



              </div>
            </div>
          </div>
        </div>
      </div>
      {/* <div className="about wow fadeInUp" data-wow-delay="0.1s">
    <div className="container">
        <div className="row align-items-center">
            
            <div className="col-lg-5 col-md-6">
                 <div className="about-text">
                 <p>
                   
                   Counseling Sessions are designed to understand the  Individual Nature ( Swabhava). Helps them to understand there Nature, qualities & remove the myths of Mind. Develop a balanced Emotional well-being & incorporate it in Day to day life to reach  their highest potential 
                   Healthy lifestyle- are designed to understand the circadian rhythm of the Individual & help the person to get the best of there of Life 
                   </p>
                </div>
            </div>
            <div className="col-lg-7 col-md-6">
                <div className="about-img">
                    <img src="img/slider2.jpg" alt="about"/>
                </div>
            </div>
        </div>
    </div>
</div> */}
    </div>
  )
}

export default Counselleing
