// 
import React from 'react';

const TestiMonialsDetails = ({ testiMonialDetail }) => {
  const { name, address, description, img } = testiMonialDetail;

  return (
    <div className="">
      <div className="">
        <img className="" src={img} alt={name} />
        <div className="">
          <p style={{ color: "black" }}>{description}</p>
        </div>
      </div>
      <div className="">
        <h5><b>{name}</b></h5>
        <small>{address}</small>
      </div>
    </div>
  );
};

export default TestiMonialsDetails;
