



// import React from 'react';
// import { MdKeyboardDoubleArrowRight } from "react-icons/md";

// const Videoconsultancy = () => {
//   const containerStyle = {
//     marginTop:'-100px',
//     display: 'flex',
//     alignItems: 'center',
//     justifyContent: 'space-between', // Ensures there is space between the elements
//     padding: '20px',
//     textAlign: 'justify',
//   };

//   const imageStyle = {
//     marginLeft: '20px',
//     maxWidth: '50%', // Adjusts the width of the image
//     height: 'auto',
//   };

//   const textStyle = {
//     lineHeight: '1.6',
//     fontSize: '16px',
//     maxWidth: '50%', 
//     color:'black'    // Adjusts the width of the text container
//   };

//   return (
//     <div>
//     <div className="page-headervideo " >
//     <div className="container resposnive_breadmcrum">
//       <div className="row">
//         <div className="col-12" style={{fontFamily:"Circular"}}>

//           <h2 style={{ color: "black"}}>Video-Consultancy</h2>
//         </div>
//         <div className="col-12" style={{fontFamily:"Circular"}}>
//           <a href="/" >Home</a>
//           <a href="#" ><MdKeyboardDoubleArrowRight/>Video-Consultancy </a>
//         </div>
//       </div>
//     </div>
//   </div>
//     <div style={containerStyle} >
       
//       <div style={textStyle}>
//       <h1 style={{marginTop:'-100px'}}>Tele - Consultations</h1><br/>
//         <p>
//           AnantaSaukhyam presents Tele-Holistic Consultations, a pioneering video consultancy service that empowers you to experience infinite wellness from anywhere. Transcending geographical barriers, our remote consultations provide convenient access to our multidisciplinary team of experts, offering seamless integration of Acupuncture, Pressure Therapy, Counseling, Diet & Lifestyle guidance, Yoga Therapy, Ayurveda, and Homeopathy.
//         </p>
//         <p>
//           Unlock your highest potential and lead a life of quality through personalized virtual sessions tailored to your unique needs. Embrace the transformative power of Tele-Holistic Consultations, where the essence of AnantaSaukhyam's vision – Health for All – is brought directly to you, enabling you to explore the boundless realms of holistic well-being from the comfort of your personal space.
//         </p>
//       </div>
//       <img src="./VideoConsultancy.jpg" alt="AnantaSaukhyam" style={imageStyle} />
//     </div></div>
//   );
// };

// export default Videoconsultancy;

// import React from 'react';
// import { MdKeyboardDoubleArrowRight } from "react-icons/md";

// const Videoconsultancy = () => {
//   const containerStyle = {
//     marginTop: '-100px',
//     display: 'flex',
//     alignItems: 'center',
//     justifyContent: 'space-between',
//     padding: '20px',
//     textAlign: 'justify',
//     flexDirection: 'row', // Default to row layout
//   };

//   const imageStyle = {
//     marginLeft: '20px',
//     maxWidth: '50%',
//     height: 'auto',
//   };

//   const textStyle = {
//     lineHeight: '1.6',
//     fontSize: '16px',
//     maxWidth: '50%',
//     color: 'black',
//   };

//   const mobileContainerStyle = {
//     ...containerStyle,
//     flexDirection: 'column', // Stack elements vertically on mobile
//   };

//   const mobileImageStyle = {
//     ...imageStyle,
//     marginLeft: '0',
//     maxWidth: '100%', // Full width on mobile
//     marginBottom: '20px', // Add space below the image
//   };

//   const mobileTextStyle = {
//     ...textStyle,
//     maxWidth: '100%', // Full width on mobile
//   };

//   return (
//     <div>
//       <div className="page-headervideo">
//         <div className="container resposnive_breadmcrum">
//           <div className="row">
//             <div className="col-12" style={{ fontFamily: "Circular" }}>
//               <h2 >Video-Consultancy</h2>
//             </div>
//             <div className="col-12" style={{ fontFamily: "Circular" }}>
//               <a href="/" >Home</a>
//               <a href="#" ><MdKeyboardDoubleArrowRight />Video-Consultancy </a>
//             </div>
//           </div>
//         </div>
//       </div>
//       <div style={window.innerWidth < 768 ? mobileContainerStyle : containerStyle}>
//         <img src="./VideoConsultancy.jpg" alt="AnantaSaukhyam" style={window.innerWidth < 768 ? mobileImageStyle : imageStyle} />
//         <div style={window.innerWidth < 768 ? mobileTextStyle : textStyle}>
//           <h1 style={{ marginTop: '-50px' }}>Tele - Consultations</h1><br />
//           <p>
//             AnantaSaukhyam presents Tele-Holistic Consultations, a pioneering video consultancy service that empowers you to experience infinite wellness from anywhere. Transcending geographical barriers, our remote consultations provide convenient access to our multidisciplinary team of experts, offering seamless integration of Acupuncture, Pressure Therapy, Counseling, Diet & Lifestyle guidance, Yoga Therapy, Ayurveda, and Homeopathy.
//           </p>
//           <p>
//             Unlock your highest potential and lead a life of quality through personalized virtual sessions tailored to your unique needs. Embrace the transformative power of Tele-Holistic Consultations, where the essence of AnantaSaukhyam's vision – Health for All – is brought directly to you, enabling you to explore the boundless realms of holistic well-being from the comfort of your personal space.
//           </p>
//         </div>
//       </div>
//     </div>
//   );
// };

// export default Videoconsultancy;




import React from 'react';
import { MdKeyboardDoubleArrowRight } from "react-icons/md";

const Videoconsultancy = () => {
  const containerStyle = {
    marginTop: '-100px',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'space-between',
    padding: '20px',
    textAlign: 'justify',
    flexDirection: 'row',
  };

  const imageStyle = {
    marginLeft: '20px',
    maxWidth: '50%',
    height: 'auto',
  };

  const textStyle = {
    lineHeight: '1.6',
    fontSize: '16px',
    maxWidth: '50%',
    color: 'black',
  };

  const mobileContainerStyle = {
    ...containerStyle,
    flexDirection: 'column',
    alignItems: 'center',
  };

  const mobileImageStyle = {
    ...imageStyle,
    marginLeft: '0',
    maxWidth: '100%',
    marginBottom: '20px',
  };

  const mobileTextStyle = {
    ...textStyle,
    maxWidth: '100%',
    textAlign: 'center',
  };

  const titleStyle = {
    marginTop: '-50px',
  };

  const pageHeaderStyle = {
    fontFamily: 'Circular',
  };

  // Determine if the view is mobile
  const isMobileView = window.innerWidth < 768;

  return (
    <div>
      <div className="page-headervideo">
        <div className="container resposnive_breadmcrum">
          <div className="row">
            <div className="col-12" style={pageHeaderStyle}>
              <h2>Video-Consultancy</h2>
            </div>
            <div className="col-12" style={pageHeaderStyle}>
              <a href="/">Home</a>
              <a href="#"><MdKeyboardDoubleArrowRight />Video-Consultancy</a>
            </div>
          </div>
        </div>
      </div>
      <div style={isMobileView ? mobileContainerStyle : containerStyle}>
        <img src="./VideoConsultancy.jpg" alt="AnantaSaukhyam" style={isMobileView ? mobileImageStyle : imageStyle} />
        <div style={isMobileView ? mobileTextStyle : textStyle}>
          <h1 style={titleStyle}>Tele - Consultations</h1><br />
          <p>
            AnantaSaukhyam presents Tele-Holistic Consultations, a pioneering video consultancy service that empowers you to experience infinite wellness from anywhere. Transcending geographical barriers, our remote consultations provide convenient access to our multidisciplinary team of experts, offering seamless integration of Acupuncture, Pressure Therapy, Counseling, Diet & Lifestyle guidance, Yoga Therapy, Ayurveda, and Homeopathy.
          </p>
          <p>
            Unlock your highest potential and lead a life of quality through personalized virtual sessions tailored to your unique needs. Embrace the transformative power of Tele-Holistic Consultations, where the essence of AnantaSaukhyam's vision – Health for All – is brought directly to you, enabling you to explore the boundless realms of holistic well-being from the comfort of your personal space.
          </p>
        </div>
      </div>
    </div>
  );
};

export default Videoconsultancy;

