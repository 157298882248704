// import React from 'react';

// class Doctorteam extends React.Component {
//     render() {
//         return (
//             <div>
               


              

//                 {/* Team Start */}
//                 <div className="team">
//                     <div className="container">
//                         <div className="section-header text-center wow zoomIn" data-wow-delay="0.1s">
//                             <p>Yoga Trainer</p>
//                             <h2>Expert Yoga Trainer</h2>
//                         </div>
//                         <div className="row">
//                             {/* Team Items */}
//                             {/* Repeat this block for each team member */}
//                             <div className="col-lg-3 col-md-6 wow fadeInUp" data-wow-delay="0.0s">
//                                 <div className="team-item">
//                                     <div className="team-img">
//                                         <img src="img/team-1.jpg" alt="Image" />
//                                         <div className="team-social">
//                                             <a href=""><i className="fab fa-twitter"></i></a>
//                                             <a href=""><i className="fab fa-facebook-f"></i></a>
//                                             <a href=""><i className="fab fa-linkedin-in"></i></a>
//                                             <a href=""><i className="fab fa-instagram"></i></a>
//                                         </div>
//                                     </div>
//                                     <div className="team-text">
//                                         <h2> Dheemanth RM </h2>
//                                         <p>20 yrs</p>
//                                     </div>
//                                 </div>
//                             </div>
//                             <div className="col-lg-3 col-md-6 wow fadeInUp" data-wow-delay="0.0s">
//                                 <div className="team-item">
//                                     <div className="team-img">
//                                         <img src="img/team-1.jpg" alt="Image" />
//                                         <div className="team-social">
//                                             <a href=""><i className="fab fa-twitter"></i></a>
//                                             <a href=""><i className="fab fa-facebook-f"></i></a>
//                                             <a href=""><i className="fab fa-linkedin-in"></i></a>
//                                             <a href=""><i className="fab fa-instagram"></i></a>
//                                         </div>
//                                     </div>
//                                     <div className="team-text">
//                                         <h2>Jayamala </h2>
//                                         <p>46 yrs</p>
//                                     </div>
//                                 </div>
//                             </div>
//                             <div className="col-lg-3 col-md-6 wow fadeInUp" data-wow-delay="0.0s">
//                                 <div className="team-item">
//                                     <div className="team-img">
//                                         <img src="img/team-1.jpg" alt="Image" />
//                                         <div className="team-social">
//                                             <a href=""><i className="fab fa-twitter"></i></a>
//                                             <a href=""><i className="fab fa-facebook-f"></i></a>
//                                             <a href=""><i className="fab fa-linkedin-in"></i></a>
//                                             <a href=""><i className="fab fa-instagram"></i></a>
//                                         </div>
//                                     </div>
//                                     <div className="team-text">
//                                         <h2>Nithyashree RM </h2>
//                                         <p>13 yrs</p>
//                                     </div>
//                                 </div>
//                             </div>
//                             <div className="col-lg-3 col-md-6 wow fadeInUp" data-wow-delay="0.0s">
//                                 <div className="team-item">
//                                     <div className="team-img">
//                                         <img src="img/team-1.jpg" alt="Image" />
//                                         <div className="team-social">
//                                             <a href=""><i className="fab fa-twitter"></i></a>
//                                             <a href=""><i className="fab fa-facebook-f"></i></a>
//                                             <a href=""><i className="fab fa-linkedin-in"></i></a>
//                                             <a href=""><i className="fab fa-instagram"></i></a>
//                                         </div>
//                                     </div>
//                                     <div className="team-text">
//                                         <h2>Swarna Gowri </h2>
//                                         <p>47yrs</p>
//                                     </div>
//                                 </div>
//                             </div>
//                             {/* End of Team Item */}
//                         </div>
//                     </div>
//                 </div>
//                 {/* Team End */}

               



//                 {/* Footer Start */}
                
//                 {/* Footer End */}

//                 <a href="#" className="back-to-top"><i className="fa fa-chevron-up"></i></a>

//                 {/* JavaScript Libraries */}
//                 <script src="https://code.jquery.com/jquery-3.4.1.min.js"></script>
//                 <script src="https://stackpath.bootstrapcdn.com/bootstrap/4.4.1/js/bootstrap.bundle.min.js"></script>
//                 <script src="lib/easing/easing.min.js"></script>
//                 <script src="lib/wow/wow.min.js"></script>
//                 <script src="lib/owlcarousel/owl.carousel.min.js"></script>
//                 <script src="lib/isotope/isotope.pkgd.min.js"></script>
//                 <script src="lib/lightbox/js/lightbox.min.js"></script>

//                 {/* Contact Javascript File */}
//                 <script src="mail/jqBootstrapValidation.min.js"></script>
//                 <script src="mail/contact.js"></script>

//                 {/* Template Javascript */}
//                             {/* </script>
//                 <script src="js/main.js"></script> */}
//             </div>
//         );
//     }
// }

// export default Doctorteam;



// import React from 'react';
// import { Link } from 'react-router-dom';

// // Define a SocialLinks component
// const SocialLinks = () => (
//     <div className="team-social">
//         {/* <a href=""><i className=""></i></a>
//         <a href=""><i className=""></i></a>
//         <a href=""><i className=""></i></a>
//         <a href=""><i className=""></i></a> */}
//     </div>
// );

// class Doctorteam extends React.Component {
//     render() {
//         return (
//             <div>
//                 {/* Team Start */}
//                 <div className="team">
//                     <div className="container">
//                         <div className="section-header text-center wow zoomIn" data-wow-delay="0.1s">
//                             <p>Our Team</p>
//                             <h2>Consultants</h2>
//                         </div>
//                         <div className="row">
//                             {/* Team Items */}
//                             {/* Repeat this block for each team member */}
                         
//                             <div className="col-lg-3 col-md-6 wow fadeInUp" data-wow-delay="0.0s">
//                                 <div className="team-item">
//                                     <div className="team-img">
//                                         <img src="./DoctorTeam/swarna.jpg" alt="Image"  height={320}/>
//                                         <SocialLinks />
//                                     </div>
//                                     <div className="team-text">
//                                         <h2>Dr. Swarna Gowri</h2>
//                                         <p>BNYS PGDHHM</p>
//                                         <p style={{fontSize:'bold',color:'black'}}>Founder</p>
//                                         <p>A senior naturopath with 20 years of expertise. </p>
//                                         <Link to='/DoctorsDetails'>ReadMore...</Link>
//                                     </div>
//                                 </div>
//                             </div>
//                             <div className="col-lg-3 col-md-6 wow fadeInUp" data-wow-delay="0.0s">
//                                 <div className="team-item">
//                                     <div className="team-img">
//                                         <img src="./DoctorTeam/Akshata.jpeg" alt="Image"height={320} />
//                                         <SocialLinks />
//                                     </div>
//                                     <div className="team-text">
//                                         <h2>Dr. Akashtha Hallikere</h2>
//                                         <p> BNYS 
//                                         </p>
//                                         <p>
//                                         A senior  Acupuncturist with 15 years of experience in the field.</p>

//                                         <Link to='/DoctorsDetails'>ReadMore...</Link>
//                                     </div>
//                                 </div>
//                             </div>
                           
//                             <div className="col-lg-3 col-md-6 wow fadeInUp" data-wow-delay="0.0s">
//                                 <div className="team-item">
//                                     <div className="team-img">
//                                         <img src="./DoctorTeam/Poornima.jpeg" alt="Image"height={320} />
//                                         <SocialLinks />
//                                     </div>
//                                     <div className="team-text">
//                                         <h2>Dr. Poornima B k </h2>
//                                         <p> BNYS</p>
//                                         <p>A senior  Acupuncturist  and Naturopathy with 19 years of experience in these fields.</p>
//                                         <Link to='/DoctorsDetails'>ReadMore...</Link>
//                                     </div>
//                                 </div>
//                             </div>
//                             <div className="col-lg-3 col-md-6 wow fadeInUp" data-wow-delay="0.0s">
//                                 <div className="team-item">
//                                     <div className="team-img">
//                                         <img src="./DoctorTeam/Vireeshka.jpeg" alt="Image" height={320} />
//                                         <SocialLinks />
//                                     </div>
//                                     <div className="team-text">
//                                         <h2>Dr. Vrisheek Suresh</h2>
//                                         <p>BNYS</p>
//                                         <p>A senior naturopath with 17 years of expertise. </p>
//                                         <Link to='/DoctorsDetails'>ReadMore...</Link>     {/* <p>she integrates personalized, holistic treatment strategies that emphasize education on natural remedies and lifestyle corrections. Her approach includes therapies such as acupuncture, diet, and yoga. She has a special interest in, and significant experience with, working with ADHD and autism.</p> */}
//                                     </div>
//                                 </div>
//                             </div>
//                             {/* End of Team Item */}
//                         </div>
//                     </div>
//                 </div>
//                 {/* Team End */}

//                 {/* Footer Start */}
//                 {/* Footer End */}

//                 <a href="#" className="back-to-top"><i className="fa fa-chevron-up"></i></a>

//                 {/* JavaScript Libraries */}
//                 <script src="https://code.jquery.com/jquery-3.4.1.min.js"></script>
//                 <script src="https://stackpath.bootstrapcdn.com/bootstrap/4.4.1/js/bootstrap.bundle.min.js"></script>
//                 <script src="lib/easing/easing.min.js"></script>
//                 <script src="lib/wow/wow.min.js"></script>
//                 <script src="lib/owlcarousel/owl.carousel.min.js"></script>
//                 <script src="lib/isotope/isotope.pkgd.min.js"></script>
//                 <script src="lib/lightbox/js/lightbox.min.js"></script>

//                 {/* Contact Javascript File */}
//                 <script src="mail/jqBootstrapValidation.min.js"></script>
//                 <script src="mail/contact.js"></script>

//                 {/* Template Javascript */}
//                 {/* <script src="js/main.js"></script> */}
//             </div>
//         );
//     }
// }

// export default Doctorteam;




import React, { useState } from 'react';
import { Link } from 'react-router-dom';
import './Doctorteam.css'; // Import the CSS file

// Define a SocialLinks component
const SocialLinks = () => (
    <div className="team-social">
        {/* <a href=""><i className=""></i></a>
        <a href=""><i className=""></i></a>
        <a href=""><i className=""></i></a>
        <a href=""><i className=""></i></a> */}
    </div>
);

const TeamMember = ({ name, degree, title, description, additionalContent, image }) => {
    const [isReadMoreVisible, setReadMoreVisible] = useState(false);

    const toggleReadMore = () => {
        setReadMoreVisible(!isReadMoreVisible);
    };

    return (
        <div className="col-lg-3 col-md-6 wow fadeInUp" data-wow-delay="0.0s">
            <div className="team-item">
                <div className="team-img">
                    <img src={image} alt="Image" height={320} />
                    <SocialLinks />
                </div>
                <div className="team-text">
                    <h2>{name}</h2>
                    
                    <p style={{ fontWeight: 'bold', color: 'black' }}>{title}</p><p>{degree}</p>
                    <p>{description}</p>
                    <div className={`read-more-content ${isReadMoreVisible ? 'open' : 'close'}`}>
                        <p>{additionalContent}</p>
                    </div>
                    <button onClick={toggleReadMore}>
                        {isReadMoreVisible ? 'Read Less...' : 'Read More...'}
                    </button>
                </div>
            </div>
        </div>
    );
};

class Doctorteam extends React.Component {
    render() {
        return (
            <div>
                {/* Team Start */}
                <div className="team">
                    <div className="container">
                        <div className="section-header text-center wow zoomIn" data-wow-delay="0.1s">
                            <p>Our Team</p>
                            <h2>Consultants</h2>
                        </div>
                        <div className="row">
                            <TeamMember
                                name="Dr. Swarna Gowri"
                                degree="BNYS, PGDHHM"
                                title="Founder"
                                description="A senior naturopath with 20 years of expertise."
                                additionalContent="She is practicing Yoga & Naturopathy since 2003, has over 20 years of experience treating patients using Naturopathy, Acupuncture, Counselling and Medical Yoga, not only in India, but across the world. She specializes in treating disorders such as chronic pain, hypertension, diabetes, high cholesterol, PCOS, infertility, Skin disorders, Allergies, Arthritis, sleep disorders among others."
                                image="./DoctorTeam/swarna.jpg"
                            />
                            <TeamMember
                                name="Dr. Akashtha Hallikere"
                                degree="BNYS"
                                title=""
                                description="A senior Acupuncturist with 15 years of experience in the field."
                                additionalContent="She  is an Acupuncturist in Hebbal, Bangalore and has an experience of 15 years in this field. Dr. Akshata Hallikeri practices at Akshoos Clinic in Hebbal, Bangalore. She completed BNYS. Some of the services provided by the therapist are: Yoga during pregnancy, Fertility Acupuncture, Acupuncture, Acupressure and Infertility Treatment etc."
                                image="./DoctorTeam/Akshata.jpeg"
                            />
                            <TeamMember
                                name="Dr. Poornima B K"
                                degree="BNYS"
                                title=""
                                description="A senior Acupuncturist and Naturopath with 19 years of experience in these fields."
                                additionalContent="She a wellness coach, holds a bachelor's degree in Naturopathy and Yogic sciences from SDM College, Ujire, and a Master's in Acupuncture through the Federation of Acupuncture Board, Bangalore. She has also trained at Jindal Nature Cure Hospital, Bangalore. With over 15 years of experience in Naturopathy and Yogic sciences, she has treated various chronic conditions through clinical acupuncture, diet and nutrition counseling, yoga therapy, and other natural and drugless treatment modalities."
                                image="./DoctorTeam/Poornima.jpeg"
                            />
                            <TeamMember
                                name="Dr. Vrisheek Suresh"
                                degree="BNYS"
                                title=""
                                description="A senior naturopath with 17 years of expertise."
                                additionalContent="She is a senior naturopath with 17 years of expertise who integrates personalized, holistic treatment strategies that emphasize education on natural remedies and lifestyle corrections. Her approach includes therapies such as acupuncture, diet, and yoga. She has a special interest in, and significant experience with, working with ADHD and autism."
                                image="./DoctorTeam/Vireeshka.jpeg"
                            />
                        </div>
                    </div>
                </div>
                {/* Team End */}

                <a href="#" className="back-to-top"><i className="fa fa-chevron-up"></i></a>

                {/* JavaScript Libraries */}
                <script src="https://code.jquery.com/jquery-3.4.1.min.js"></script>
                <script src="https://stackpath.bootstrapcdn.com/bootstrap/4.4.1/js/bootstrap.bundle.min.js"></script>
                <script src="lib/easing/easing.min.js"></script>
                <script src="lib/wow/wow.min.js"></script>
                <script src="lib/owlcarousel/owl.carousel.min.js"></script>
                <script src="lib/isotope/isotope.pkgd.min.js"></script>
                <script src="lib/lightbox/js/lightbox.min.js"></script>

                {/* Contact Javascript File */}
                <script src="mail/jqBootstrapValidation.min.js"></script>
                <script src="mail/contact.js"></script>

                {/* Template Javascript */}
                {/* <script src="js/main.js"></script> */}
            </div>
        );
    }
}

export default Doctorteam;
