import React from "react";
import '../css/style.css';
import Slider11 from '../components/Slider11'
import HomeSliderTrail from "./HomeSliderTrail";
import Slider from "../components/slider/Slider";
// import Slider from "react-slick";

// import NewSlider from '../pages/NewSlider'
import NewHomeSlider from "../pages/NewHomeSlider";
import NewSlider1 from "../pages/NewSlider1";


const Hero=()=>{

    return(

        <>
        {/* <Slider/> */}
         
           {/* <HomeSlider/> */}
           {/* <Slider11 /> */}
           {/* <HomeSliderTrail/> */}
  {/* <NewSlider/> */}
  {/* <NewHomeSlider/> */}
  {/* <NewSlider1/> */}
  <Slider11/>

        </>
    )
}

export default Hero;