

import React, { useState,useEffect } from 'react'
import Hero from '../components/Hero'
import About from '../components/About';
import Services from '../components/Services';
import Class from '../components/Class';
import Discount from '../components/Discount';

import Blogs from '../components/Blogs';

import 'react-datepicker/dist/react-datepicker.css';


import ReviewSlider from '../components/ReviewSlider';
import TestiMonials from '../pages/TestiMonials'
import YogaWebsite from './Doctorteam';
import Doctorteam from './Doctorteam';


const Home = () => {
    useEffect(() => {
        window.scrollTo(0, 0); // Scrolls to the top of the page
    }, []);
    const [selectedDate, setSelectedDate] = useState(null);
    const [selectedTime, setSelectedTime] = useState(null);
    return (
        <>

            <Hero />
            <About />
            <TestiMonials />
            <Services />
     <Doctorteam/>
            <Class />
            <Discount />
            {/* <Price/> */}
            {/* <Testimonials /> */}
           

            {/* <HomeServices /> */}

            {/* <Team/> */}
            <Blogs />
            {/* <Testimonialss/> */}
            <ReviewSlider />




        </>
    )
}

export default Home;