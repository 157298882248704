import React from 'react'
import { Link } from 'react-router-dom';

const Blogs = () => {
    return (
        <>
            <div class="blog">
                <div class="container">
                    <div class="section-header text-center wow zoomIn" data-wow-delay="0.1s">
                        <p>Yoga @</p>
                        <h2 >Corporate World !</h2>
                    </div>
                    <div class="row blog-page">

                        <div class="col-lg-4 col-md-6 wow fadeInUp" data-wow-delay="0.0s">
                        <Link to="/seminars">
                            <div class="blog-item">
                                <div class="blog-img">
                                    <img src="img/corp 8.jpeg" alt="Blog" />
                                </div>
                                <div class="blog-img">

                                </div>
                                <div class="blog-text">
                                    <h2></h2>
                                    <div class="blog-meta">
                                        {/* <p><i class="far fa-list-alt"></i></p>
                                    <p><i class="far fa-calendar-alt"></i></p>
                                    <p><i class="far fa-comments"></i>5</p> */}
                                    </div>
                                    <p>

                                    </p>
                                    {/* <a class="btn" href=""> <i class="fa fa-angle-right"></i></a> */}
                                </div>
                            </div>
                            </Link>
                        </div>

                        <div class="col-lg-4 col-md-6 wow fadeInUp" data-wow-delay="0.2s">
                        <Link to="/seminars">
                            <div class="blog-item">
                                <div class="blog-img">
                                    <img src="img/corp 6.jpeg" alt="Blog" />
                                </div>
                                <div class="blog-text">
                                    <h2></h2>
                                    <div class="blog-meta">
                                        {/* <p><i class="far fa-list-alt"></i>Body Fitness</p>
                                    <p><i class="far fa-calendar-alt"></i>01-Jan-2045</p>
                                    <p><i class="far fa-comments"></i>5</p> */}
                                    </div>
                                    <p>

                                    </p>
                                    {/* <a class="btn" href="">Read More <i class="fa fa-angle-right"></i></a> */}
                                </div>
                            </div>
                            </Link>
                        </div>

                        <div class="col-lg-4 col-md-6 wow fadeInUp" data-wow-delay="0.4s">
                        <Link to="/seminars">
                            <div class="blog-item">
                                <div class="blog-img">
                                    <img src="img\corp 7.jpeg" alt="Blog" />
                                </div>
                                <div class="blog-text">
                                    <h2></h2>
                                    {/* <div class="blog-meta">
                                    <p><i class="far fa-list-alt"></i>Body Fitness</p>
                                    <p><i class="far fa-calendar-alt"></i>01-Jan-2045</p>
                                    <p><i class="far fa-comments"></i>5</p>
                                </div>
                                <p>
                                    Lorem ipsum dolor sit amet elit. Neca pretim miura bitur facili ornare velit non vulpte liqum metus tortor
                                </p>
                                <a class="btn" href="">Read More <i class="fa fa-angle-right"></i></a> */}
                                </div>
                            </div>
                            </Link>
                        </div>

                        <div class="col-lg-4 col-md-6 wow fadeInUp" data-wow-delay="0.6s">
                        <Link to="/seminars">
                            <div class="blog-item">
                                <div class="blog-img">
                                    <img src="img/corp10.jpeg" alt="Blog" />
                                </div>
                                <div class="blog-text">
                                    <h2></h2>
                                    {/* <div class="blog-meta">
                                    <p><i class="far fa-list-alt"></i>Body Fitness</p>
                                    <p><i class="far fa-calendar-alt"></i>01-Jan-2045</p>
                                    <p><i class="far fa-comments"></i>5</p>
                                </div>
                                <p>
                                    Lorem ipsum dolor sit amet elit. Neca pretim miura bitur facili ornare velit non vulpte liqum metus tortor
                                </p>
                                <a class="btn" href="">Read More <i class="fa fa-angle-right"></i></a> */}
                                </div>
                            </div>
                            </Link>
                        </div>

                        <div class="col-lg-4 col-md-6 wow fadeInUp" data-wow-delay="0.8s">
                        <Link to="/seminars">
                            <div class="blog-item">
                                <div class="blog-img">
                                    <img src="img1/yoga2.jpg" alt="Blog" />
                                </div>
                                <div class="blog-text">
                                    <h2></h2>
                                    {/* <div class="blog-meta">
                                    <p><i class="far fa-list-alt"></i>Body Fitness</p>
                                    <p><i class="far fa-calendar-alt"></i>01-Jan-2045</p>
                                    <p><i class="far fa-comments"></i>5</p>
                                </div>
                                <p>
                                    Lorem ipsum dolor sit amet elit. Neca pretim miura bitur facili ornare velit non vulpte liqum metus tortor
                                </p>
                                <a class="btn" href="">Read More <i class="fa fa-angle-right"></i></a> */}
                                </div>
                            </div>
                            </Link>
                        </div>

                        <div class="col-lg-4 col-md-6 wow fadeInUp" data-wow-delay="1s">
                        <Link to="/seminars">
                            <div class="blog-item">
                                <div class="blog-img">
                                    <img src="img/corp 10.jpg" alt="Blog" />
                                </div>
                                <div class="blog-text">
                                    <h2></h2>
                                    {/* <div class="blog-meta">
                                    <p><i class="far fa-list-alt"></i>Body Fitness</p>
                                    <p><i class="far fa-calendar-alt"></i>01-Jan-2045</p>
                                    <p><i class="far fa-comments"></i>5</p>
                                </div>
                                <p>
                                    Lorem ipsum dolor sit amet elit. Neca pretim miura bitur facili ornare velit non vulpte liqum metus tortor
                                </p>
                                <a class="btn" href="">Read More <i class="fa fa-angle-right"></i></a> */}
                                </div>
                            </div>
                            </Link>
                        </div>

                    </div>

                </div>
            </div>


            <div className="video-choose-agile py-5">
                <div className="container py-xl-5 py-lg-3">
                    <div className="row">
                   
                        <div className="col-lg-7 video">
                        <Link to="/seminars">
                            <div class="section-header text-center wow zoomIn" data-wow-delay="0.1s">
                                <p>Our</p>
                                <h2 style={{color:"#343148"}}>Videos..</h2>
                            </div>
                            {/* <iframe src="https://player.vimeo.com/video/58123228"></iframe> */}
                            <video controls width="100%">
                                <source src="img1/video1.mp4" type="video/webm" />
                                {/* <source src="/video-example.mp4" type="video/mp4"/> */}
                                Sorry, your browser doesn't support videos.
                            </video>
                            </Link>
                        </div>



                        <div className="col-lg-5 events">
                            <h3 className="title text-capitalize font-weight-light text-dark text-center mb-5">
                                <span className="font-weight-bold"></span>
                            </h3>
                            <div className="events-w3ls">
                                <div className="d-flex">
                                    <div className="col-sm-2 col-3 events-up p-3 text-center">
                                        <h5 className="text-white font-weight-bold">1st week
                                            <span className="border-top font-weight-light pt-2 mt-2">Aug</span>
                                        </h5>
                                    </div>
                                    {/* <div className="col-sm-10 col-9 events-right">
                                        <h4 className="text-dark">Admission For Internship</h4>
                                        <ul className="list-unstyled">
                                            <li className="my-2">
                                                <i className="far fa-clock mr-2"></i>10.00 am - 4.30 pm</li>
                                            <li>
                                                <i className="fas fa-map-marker mr-2"></i>Banglore</li>
                                        </ul>
                                    </div> */}
                                </div>
                            </div>
                            <div className="d-flex my-4">
                               
                                <div className="col-sm-12 col-9 events-right" style={{marginTop:"-50px"}}>
                                    <h4 className="text-dark">Workshops and Seminars undertaken</h4>
                                    <p style={{color:"black", textAlign:"justify"}}>
                     
                    
                  
                    <ul>
                    <li>	Desktop Yoga</li>
                    <li>	Emotional Well Being </li>
                    <li>	Fertility Health </li>
                    <li>	Healthy Lifestyle </li>
                    <li>	Know What To Eat </li>
                    <li>	Meditation </li>
                    <li>	Naturopathy  </li>
                    <li>	Pregnancy Health </li>
                    <li>	Prenatal & Antenatal Care </li>
                    <li>	Pressure Threapy For Healthy Living  </li>
                    <li>	Sessions On Pranayama </li>
                    <li>	Spine Health </li>
                    <li>	Stress Deletion </li>
                    <li>	Women's Health </li>
                    <li>	Pediatric Health </li>
                    <li>	Geriatric Health </li>
                    </ul>        

                    </p>
                                </div>
                            </div>
                            <div className="d-flex">
                                {/* <div className="col-sm-2 col-3 events-up p-3 text-center">
                                    <h5 className="text-white font-weight-bold">1st week
                                        <span className="border-top font-weight-light pt-2 mt-2">Aug</span>
                                    </h5>
                                </div>
                                <div className="col-sm-10 col-9 events-right">
                                    <h4 className="text-dark">Digital Marketting </h4>
                                    <ul className="list-unstyled">
                                        <li className="my-2">
                                            <i className="far fa-clock mr-2"></i>10.00 am - 4.30 pm</li>
                                        <li>
                                            <i className="fas fa-map-marker mr-2"></i>Banglore.</li>
                                    </ul>
                                </div> */}
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </>
    )
}

export default Blogs