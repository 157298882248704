import React,{useEffect} from 'react'
import { MdKeyboardDoubleArrowRight } from "react-icons/md";


const ClassNameName = () => {
    useEffect(() => {
        window.scrollTo(0, 0); // Scrolls to the top of the page
    }, []);
    
    return (
        <>
            <div className="page-headerg" >
                <div className="container resposnive_breadmcrum">
                    <div className="row">
                        <div className="col-12">

                            <h2 style={{ fontFamily:"Circular" }}>Gallery</h2>
                        </div>
                        <div className="col-12" style={{fontFamily:"Circular"}}>
                            <a  href="/">Home</a>
                            <a href="#"><MdKeyboardDoubleArrowRight/>Gallery</a>
                        </div>
                    </div>
                </div>
            </div>

            {/* <div className="page-header-classname">
            <div className="container">
                <div className="row">
                    <div className="col-12">
                        <h2>className</h2>
                    </div>
                    <div className="col-12">
                        <a href="">Home</a>
                        <a href="">Name</a>
                    </div>
                </div>
            </div>
        </div> */}

            <div class="class">
                <div class="container">
                    <div class="section-header text-center wow zoomIn" data-wow-delay="0.1s">
                        <p></p>
                        <h2  >Gallery</h2>
                    </div>
                    <div class="row">
                        <div class="col-12">
                            {/* <ul id="class-filter">
                    <li data-filter="*" class="filter-active">All Classes</li>
                    <li data-filter=".filter-1">Pilates Yoga</li>
                    <li data-filter=".filter-2">Hatha Yoga</li>
                    <li data-filter=".filter-3">Vinyasa yoga</li>
                </ul> */}
                        </div>
                    </div>
                    <div class="row class-container">

                        {/* <div class="col-lg-4 col-md-6 col-sm-12 class-item filter-1 wow fadeInUp" data-wow-delay="0.0s">
                            <div class="class-wrap">
                                <div class="class-img">
                                    <img src="img/New folder/corp1.jpeg" alt="Image" className="zoom-image " style={{height:"240px"}} />
                                </div>
                               
                            </div>
                        </div> */}
                        <div class="col-lg-4 col-md-6 col-sm-12 class-item filter-2 wow fadeInUp" data-wow-delay="0.2s" style={{ height: "240px" }}>
                            <div class="class-wrap">
                                <div class="class-img">
                                    <img src="img/New folder/WhatsApp Image 2023-09-25 at 16.13.05.jpeg" alt="Image" className="zoom-image " />
                                </div>
                           
                            </div>
                        </div>
                        <div class="col-lg-4 col-md-6 col-sm-12 class-item filter-3 wow fadeInUp" data-wow-delay="0.4s" >
                            <div class="class-wrap">
                                <div class="class-img">
                                    <img src="img/New folder/gallery.jpeg" alt="Image" className="zoom-image " style={{height:"240px"}} />
                                </div>
                             
                            </div>
                        </div>


                        <div class="col-lg-4 col-md-6 col-sm-12 class-item filter-1 wow fadeInUp" data-wow-delay="0.6s">
                            <div class="class-wrap">
                                <div class="class-img">
                                    <img src="img/New folder/11.jpeg" alt="Image" className="zoom-image "  style={{height:"260px"}}/>
                                </div>
                                {/* <div class="class-text">
                        <div class="class-teacher">
                            <img src="img/teacher-4.png" alt="Image"/>
                            <h3>Lilly Fry</h3>
                            <a href="">+</a>
                        </div>
                        <h2>Hatha Yoga</h2>
                        <div class="class-meta">
                            <p><i class="far fa-calendar-alt"></i>Sun, Tue, Thu</p>
                            <p><i class="far fa-clock"></i>9:00 - 10:00</p>
                        </div>
                    </div> */}
                            </div>
                        </div>
                        <div class="col-lg-4 col-md-6 col-sm-12 class-item filter-3 wow fadeInUp" data-wow-delay="1s" style={
                            { height: "300px" }
                        }>
                            <div class="class-wrap">
                                <div class="class-img">
                                    <img src="img1/p4.jpeg" alt="Image" className="zoom-image " style={{height:"260px"}} />
                                </div>
                                {/* <div class="class-text">
                        <div class="class-teacher">
                            <img src="img/teacher-6.png" alt="Image"/>
                            <h3>Millie Harper</h3>
                            <a href="">+</a>
                        </div>
                        <h2>Vinyasa yoga</h2>
                        <div class="class-meta">
                            <p><i class="far fa-calendar-alt"></i>Sun, Tue, Thu</p>
                            <p><i class="far fa-clock"></i>9:00 - 10:00</p>
                        </div>
                    </div> */}
                            </div>
                        </div>
                        <div class="col-lg-4 col-md-6 col-sm-12 class-item filter-2 wow fadeInUp" data-wow-delay="0.8s">
                            <div class="class-wrap">
                                <div class="class-img">
                                    <img src="img/New folder/test 4.jpg" alt="Image" className="zoom-image " style={{height:"260px"}} />
                                </div>
                                {/* <div class="class-text">
                        <div class="class-teacher">
                            <img src="img/teacher-5.png" alt="Image"/>
                            <h3>Olivia Yates</h3>
                            <a href="">+</a>
                        </div>
                        <h2>Kundalini Yoga</h2>
                        <div class="class-meta">
                            <p><i class="far fa-calendar-alt"></i>Sun, Tue, Thu</p>
                            <p><i class="far fa-clock"></i>9:00 - 10:00</p>
                        </div>
                    </div> */}
                            </div>
                        </div>
                        <div class="col-lg-4 col-md-6 col-sm-12 class-item filter-3 wow fadeInUp" data-wow-delay="1s">
                            <div class="class-wrap">
                                <div class="class-img">
                                    <img src="img/New folder/IMG_20180816_084610.jpg" alt="Image" className="zoom-image "  style={{height:"260px"}} />
                                </div>
                                {/* <div class="class-text">
                        <div class="class-teacher">
                            <img src="img/teacher-6.png" alt="Image"/>
                            <h3>Millie Harper</h3>
                            <a href="">+</a>
                        </div>
                        <h2>Vinyasa yoga</h2>
                        <div class="class-meta">
                            <p><i class="far fa-calendar-alt"></i>Sun, Tue, Thu</p>
                            <p><i class="far fa-clock"></i>9:00 - 10:00</p>
                        </div>
                    </div> */}
                            </div>
                        </div>
                        <div class="col-lg-4 col-md-6 col-sm-12 class-item filter-1 wow fadeInUp" data-wow-delay="0.6s">
                            <div class="class-wrap">
                                <div class="class-img">
                                    <img src="img/New folder/IMG_20171201_083717.jpg" alt="Image" className="zoom-image "   style={{height:"260px"}}/>
                                </div>
                                {/* <div class="class-text">
                        <div class="class-teacher">
                            <img src="img/teacher-4.png" alt="Image"/>
                            <h3>Lilly Fry</h3>
                            <a href="">+</a>
                        </div>
                        <h2>Hatha Yoga</h2>
                        <div class="class-meta">
                            <p><i class="far fa-calendar-alt"></i>Sun, Tue, Thu</p>
                            <p><i class="far fa-clock"></i>9:00 - 10:00</p>
                        </div>
                    </div> */}
                            </div>
                        </div>
                        <div class="col-lg-4 col-md-6 col-sm-12 class-item filter-2 wow fadeInUp" data-wow-delay="0.8s">
                            <div class="class-wrap">
                                <div class="class-img">
                                    <img src="img/New folder/corp 7.jpeg" alt="Image" className="zoom-image "   style={{height:"260px"}}/>
                                </div>
                                {/* <div class="class-text">
                        <div class="class-teacher">
                            <img src="img/teacher-5.png" alt="Image"/>
                            <h3>Olivia Yates</h3>
                            <a href="">+</a>
                        </div>
                        <h2>Kundalini Yoga</h2>
                        <div class="class-meta">
                            <p><i class="far fa-calendar-alt"></i>Sun, Tue, Thu</p>
                            <p><i class="far fa-clock"></i>9:00 - 10:00</p>
                        </div>
                    </div> */}
                            </div>
                        </div>
                        <div class="col-lg-4 col-md-6 col-sm-12 class-item filter-3 wow fadeInUp" data-wow-delay="1s">
                            <div class="class-wrap">
                                <div class="class-img">
                                    <img src="img/New folder/corp 9.jpg" alt="Image" className="zoom-image "  style={{height:"260px"}} />
                                </div>
                                {/* <div class="class-text">
                        <div class="class-teacher">
                            <img src="img/teacher-6.png" alt="Image"/>
                            <h3>Millie Harper</h3>
                            <a href="">+</a>
                        </div>
                        <h2>Vinyasa yoga</h2>
                        <div class="class-meta">
                            <p><i class="far fa-calendar-alt"></i>Sun, Tue, Thu</p>
                            <p><i class="far fa-clock"></i>9:00 - 10:00</p>
                        </div>
                    </div> */}
                            </div>
                        </div>


                        <div class="col-lg-4 col-md-6 col-sm-12 class-item filter-3 wow fadeInUp" data-wow-delay="1s">
                            <div class="class-wrap">
                                <div class="class-img">
                                    <img src="img/WhatsApp Image 2023-10-14 at 17.26.5511.jpeg" alt="Image" className="zoom-image "   style={{height:"260px"}}/>
                                </div>
                                {/* <div class="class-text">
                        <div class="class-teacher">
                            <img src="img/teacher-6.png" alt="Image"/>
                            <h3>Millie Harper</h3>
                            <a href="">+</a>
                        </div>
                        <h2>Vinyasa yoga</h2>
                        <div class="class-meta">
                            <p><i class="far fa-calendar-alt"></i>Sun, Tue, Thu</p>
                            <p><i class="far fa-clock"></i>9:00 - 10:00</p>
                        </div>
                    </div> */}
                            </div>
                        </div>
                        <div class="col-lg-4 col-md-6 col-sm-12 class-item filter-3 wow fadeInUp" data-wow-delay="1s">
                            <div class="class-wrap">
                                <div class="class-img">
                                    <img src="img/WhatsApp Image 2023-10-14 at 17.27.01.jpeg" alt="Image" className="zoom-image "  style={{height:"260px"}} />
                                </div>
                                {/* <div class="class-text">
                        <div class="class-teacher">
                            <img src="img/teacher-6.png" alt="Image"/>
                            <h3>Millie Harper</h3>
                            <a href="">+</a>
                        </div>
                        <h2>Vinyasa yoga</h2>
                        <div class="class-meta">
                            <p><i class="far fa-calendar-alt"></i>Sun, Tue, Thu</p>
                            <p><i class="far fa-clock"></i>9:00 - 10:00</p>
                        </div>
                    </div> */}
                            </div>
                        </div>
                        <div class="col-lg-4 col-md-6 col-sm-12 class-item filter-3 wow fadeInUp" data-wow-delay="1s">
                            <div class="class-wrap">
                                <div class="class-img">
                                    <img src="img/WhatsApp Image 2023-10-14 at 17.26.58.jpeg" alt="Image" className="zoom-image " />
                                </div>
                                {/* <div class="class-text">
                        <div class="class-teacher">
                            <img src="img/teacher-6.png" alt="Image"/>
                            <h3>Millie Harper</h3>
                            <a href="">+</a>
                        </div>
                        <h2>Vinyasa yoga</h2>
                        <div class="class-meta">
                            <p><i class="far fa-calendar-alt"></i>Sun, Tue, Thu</p>
                            <p><i class="far fa-clock"></i>9:00 - 10:00</p>
                        </div>
                    </div> */}
                            </div>
                        </div>
                        <div class="col-lg-4 col-md-6 col-sm-12 class-item filter-3 wow fadeInUp" data-wow-delay="1s">
                            <div class="class-wrap">
                                <div class="class-img">
                                    <img src="img/WhatsApp Image 2023-10-14 at 17.26.59.jpeg" alt="Image" className="zoom-image " />
                                </div>
                                {/* <div class="class-text">
                        <div class="class-teacher">
                            <img src="img/teacher-6.png" alt="Image"/>
                            <h3>Millie Harper</h3>
                            <a href="">+</a>
                        </div>
                        <h2>Vinyasa yoga</h2>
                        <div class="class-meta">
                            <p><i class="far fa-calendar-alt"></i>Sun, Tue, Thu</p>
                            <p><i class="far fa-clock"></i>9:00 - 10:00</p>
                        </div>
                    </div> */}
                            </div>
                        </div>
                        <div class="col-lg-4 col-md-6 col-sm-12 class-item filter-3 wow fadeInUp" data-wow-delay="1s">
                            <div class="class-wrap">
                                <div class="class-img">
                                    <img src="img/WhatsApp Image 2023-10-14 at 17.27.00.jpeg" alt="Image" className="zoom-image " />
                                </div>
                                {/* <div class="class-text">
                        <div class="class-teacher">
                            <img src="img/teacher-6.png" alt="Image"/>
                            <h3>Millie Harper</h3>
                            <a href="">+</a>
                        </div>
                        <h2>Vinyasa yoga</h2>
                        <div class="class-meta">
                            <p><i class="far fa-calendar-alt"></i>Sun, Tue, Thu</p>
                            <p><i class="far fa-clock"></i>9:00 - 10:00</p>
                        </div>
                    </div> */}
                            </div>
                        </div>
                        <div class="col-lg-4 col-md-6 col-sm-12 class-item filter-3 wow fadeInUp" data-wow-delay="1s">
                            <div class="class-wrap">
                                <div class="class-img">
                                    <img src="img/WhatsApp Image 2023-10-14 at 17.27.02.jpeg" alt="Image" className="zoom-image " />
                                </div>
                                {/* <div class="class-text">
                        <div class="class-teacher">
                            <img src="img/teacher-6.png" alt="Image"/>
                            <h3>Millie Harper</h3>
                            <a href="">+</a>
                        </div>
                        <h2>Vinyasa yoga</h2>
                        <div class="class-meta">
                            <p><i class="far fa-calendar-alt"></i>Sun, Tue, Thu</p>
                            <p><i class="far fa-clock"></i>9:00 - 10:00</p>
                        </div>
                    </div> */}
                            </div>
                        </div>
                        <div class="col-lg-4 col-md-6 col-sm-12 class-item filter-3 wow fadeInUp" data-wow-delay="1s">
                            <div class="class-wrap">
                                <div class="class-img">
                                    <img src="img/WhatsApp Image 2023-10-14 at 17.27.08.jpeg" alt="Image" className="zoom-image " />
                                </div>
                                {/* <div class="class-text">
                        <div class="class-teacher">
                            <img src="img/teacher-6.png" alt="Image"/>
                            <h3>Millie Harper</h3>
                            <a href="">+</a>
                        </div>
                        <h2>Vinyasa yoga</h2>
                        <div class="class-meta">
                            <p><i class="far fa-calendar-alt"></i>Sun, Tue, Thu</p>
                            <p><i class="far fa-clock"></i>9:00 - 10:00</p>
                        </div>
                    </div> */}
                            </div>
                        </div>
                        <div class="col-lg-4 col-md-6 col-sm-12 class-item filter-3 wow fadeInUp" data-wow-delay="1s">
                            <div class="class-wrap">
                                <div class="class-img">
                                    <img src="img/WhatsApp Image 2023-10-14 at 17.27.09.jpeg" alt="Image" className="zoom-image " />
                                </div>
                                {/* <div class="class-text">
                        <div class="class-teacher">
                            <img src="img/teacher-6.png" alt="Image"/>
                            <h3>Millie Harper</h3>
                            <a href="">+</a>
                        </div>
                        <h2>Vinyasa yoga</h2>
                        <div class="class-meta">
                            <p><i class="far fa-calendar-alt"></i>Sun, Tue, Thu</p>
                            <p><i class="far fa-clock"></i>9:00 - 10:00</p>
                        </div>
                    </div> */}
                            </div>
                        </div>

                        <div class="col-lg-4 col-md-6 col-sm-12 class-item filter-3 wow fadeInUp" data-wow-delay="1s">
                            <div class="class-wrap">
                                <div class="class-img">
                                    <img src="img/New folder/corp 9.jpg" alt="Image" className="zoom-image "   style={{height:"260px"}}/>
                                </div>
                                {/* <div class="class-text">
                        <div class="class-teacher">
                            <img src="img/teacher-6.png" alt="Image"/>
                            <h3>Millie Harper</h3>
                            <a href="">+</a>
                        </div>
                        <h2>Vinyasa yoga</h2>
                        <div class="class-meta">
                            <p><i class="far fa-calendar-alt"></i>Sun, Tue, Thu</p>
                            <p><i class="far fa-clock"></i>9:00 - 10:00</p>
                        </div>
                    </div> */}
                            </div>
                        </div>



                        <div class="col-lg-4 col-md-6 col-sm-12 class-item filter-3 wow fadeInUp" data-wow-delay="1s">
                            <div class="class-wrap">
                                <div class="class-img">
                                    <img src="img1/WhatsApp Image 2023-11-20 at 14.55.36 (1).jpeg" alt="Image" className="zoom-image "  style={{height:"260px"}} />
                                </div>
                                {/* <div class="class-text">
                        <div class="class-teacher">
                            <img src="img/teacher-6.png" alt="Image"/>
                            <h3>Millie Harper</h3>
                            <a href="">+</a>
                        </div>
                        <h2>Vinyasa yoga</h2>
                        <div class="class-meta">
                            <p><i class="far fa-calendar-alt"></i>Sun, Tue, Thu</p>
                            <p><i class="far fa-clock"></i>9:00 - 10:00</p>
                        </div>
                    </div> */}
                            </div>
                        </div>

                        <div class="col-lg-4 col-md-6 col-sm-12 class-item filter-3 wow fadeInUp" data-wow-delay="1s">
                            <div class="class-wrap">
                                <div class="class-img">
                                    <img src="img/New folder/IMG_20171026_082412.jpg" alt="Image" className="zoom-image " style={{height:"260px"}} />
                                </div>
                                {/* <div class="class-text">
                        <div class="class-teacher">
                            <img src="img/teacher-6.png" alt="Image"/>
                            <h3>Millie Harper</h3>
                            <a href="">+</a>
                        </div>
                        <h2>Vinyasa yoga</h2>
                        <div class="class-meta">
                            <p><i class="far fa-calendar-alt"></i>Sun, Tue, Thu</p>
                            <p><i class="far fa-clock"></i>9:00 - 10:00</p>
                        </div>
                    </div> */}
                            </div>
                        </div>


                        <div class="col-lg-4 col-md-6 col-sm-12 class-item filter-3 wow fadeInUp" data-wow-delay="1s"  style={{height:"300px"}}>
                            <div class="class-wrap">
                                {/* <div class="class-img">
                                    <img src="img1/WhatsApp Image 2023-11-20 at 14.55.37.jpeg" alt="Image" className="zoom-image "  style={{height:"300px"}}/>
                                </div> */}
                                {/* <div class="class-text">
                        <div class="class-teacher">
                            <img src="img/teacher-6.png" alt="Image"/>
                            <h3>Millie Harper</h3>
                            <a href="">+</a>
                        </div>
                        <h2>Vinyasa yoga</h2>
                        <div class="class-meta">
                            <p><i class="far fa-calendar-alt"></i>Sun, Tue, Thu</p>
                            <p><i class="far fa-clock"></i>9:00 - 10:00</p>
                        </div>
                    </div> */}
                            </div>
                        </div>




















                        
                        <div class="col-lg-4 col-md-6 col-sm-12 class-item filter-3 wow fadeInUp" data-wow-delay="1s"  style={{height:"300px"}}>
                            <div class="class-wrap">
                                <div class="class-img">
                                    <img src="img/New folder (2)/WhatsApp Image 2023-11-29 at 12.20.58.jpeg" alt="Image" className="zoom-image "  style={{height:"300px"}} />
                                </div>
                                {/* <div class="class-text">
                        <div class="class-teacher">
                            <img src="img/teacher-6.png" alt="Image"/>
                            <h3>Millie Harper</h3>
                            <a href="">+</a>
                        </div>
                        <h2>Vinyasa yoga</h2>
                        <div class="class-meta">
                            <p><i class="far fa-calendar-alt"></i>Sun, Tue, Thu</p>
                            <p><i class="far fa-clock"></i>9:00 - 10:00</p>
                        </div>
                    </div> */}
                            </div>
                        </div>

                        <div class="col-lg-4 col-md-6 col-sm-12 class-item filter-3 wow fadeInUp" data-wow-delay="1s"  style={{height:"300px"}}>
                            <div class="class-wrap">
                                <div class="class-img">
                                    <img src="img/New folder (2)/WhatsApp Image 2023-11-29 at 12.20.58 (2).jpeg" alt="Image" className="zoom-image "   style={{height:"300px"}}/>
                                </div>
                                {/* <div class="class-text">
                        <div class="class-teacher">
                            <img src="img/teacher-6.png" alt="Image"/>
                            <h3>Millie Harper</h3>
                            <a href="">+</a>
                        </div>
                        <h2>Vinyasa yoga</h2>
                        <div class="class-meta">
                            <p><i class="far fa-calendar-alt"></i>Sun, Tue, Thu</p>
                            <p><i class="far fa-clock"></i>9:00 - 10:00</p>
                        </div>
                    </div> */}
                            </div>
                        </div>




                        <div class="col-lg-4 col-md-6 col-sm-12 class-item filter-3 wow fadeInUp" data-wow-delay="1s" style={{height:"300px"}}>
                            <div class="class-wrap">
                                <div class="class-img">
                                    <img src="img/New folder (2)/WhatsApp Image 2023-11-29 at 12.20.56 (1).jpeg" alt="Image" className="zoom-image "   style={{height:"300px"}}/>
                                </div>
                                {/* <div class="class-text">
                        <div class="class-teacher">
                            <img src="img/teacher-6.png" alt="Image"/>
                            <h3>Millie Harper</h3>
                            <a href="">+</a>
                        </div>
                        <h2>Vinyasa yoga</h2>
                        <div class="class-meta">
                            <p><i class="far fa-calendar-alt"></i>Sun, Tue, Thu</p>
                            <p><i class="far fa-clock"></i>9:00 - 10:00</p>
                        </div>
                    </div> */}
                            </div>
                        </div>

                        
                        <div class="col-lg-4 col-md-6 col-sm-12 class-item filter-3 wow fadeInUp" data-wow-delay="1s">
                            <div class="class-wrap">
                                <div class="class-img">
                                    <img src="img/New folder (2)/WhatsApp Image 2023-11-29 at 12.20.56 (2).jpeg" alt="Image" className="zoom-image "  style={{height:"300px"}} />
                                </div>
                                {/* <div class="class-text">
                        <div class="class-teacher">
                            <img src="img/teacher-6.png" alt="Image"/>
                            <h3>Millie Harper</h3>
                            <a href="">+</a>
                        </div>
                        <h2>Vinyasa yoga</h2>
                        <div class="class-meta">
                            <p><i class="far fa-calendar-alt"></i>Sun, Tue, Thu</p>
                            <p><i class="far fa-clock"></i>9:00 - 10:00</p>
                        </div>
                    </div> */}
                            </div>
                        </div>

                        <div class="col-lg-4 col-md-6 col-sm-12 class-item filter-3 wow fadeInUp" data-wow-delay="1s">
                            <div class="class-wrap">
                                <div class="class-img">
                                    <img src="img/New folder (2)/WhatsApp Image 2023-11-29 at 12.20.56.jpeg" alt="Image" className="zoom-image "   style={{height:"300px"}}/>
                                </div>
                                {/* <div class="class-text">
                        <div class="class-teacher">
                            <img src="img/teacher-6.png" alt="Image"/>
                            <h3>Millie Harper</h3>
                            <a href="">+</a>
                        </div>
                        <h2>Vinyasa yoga</h2>
                        <div class="class-meta">
                            <p><i class="far fa-calendar-alt"></i>Sun, Tue, Thu</p>
                            <p><i class="far fa-clock"></i>9:00 - 10:00</p>
                        </div>
                    </div> */}
                            </div>
                        </div>


                        <div class="col-lg-4 col-md-6 col-sm-12 class-item filter-3 wow fadeInUp" data-wow-delay="1s">
                            <div class="class-wrap">
                                <div class="class-img">
                                    <img src="img/New folder (2)/WhatsApp Image 2023-11-29 at 12.20.57 (1).jpeg" alt="Image" className="zoom-image "  style={{height:"300px"}} />
                                </div>
                                {/* <div class="class-text">
                        <div class="class-teacher">
                            <img src="img/teacher-6.png" alt="Image"/>
                            <h3>Millie Harper</h3>
                            <a href="">+</a>
                        </div>
                        <h2>Vinyasa yoga</h2>
                        <div class="class-meta">
                            <p><i class="far fa-calendar-alt"></i>Sun, Tue, Thu</p>
                            <p><i class="far fa-clock"></i>9:00 - 10:00</p>
                        </div>
                    </div> */}
                            </div>
                        </div>

                        <div class="col-lg-4 col-md-6 col-sm-12 class-item filter-3 wow fadeInUp" data-wow-delay="1s">
                            <div class="class-wrap">
                                <div class="class-img">
                                    <img src="img/New folder (2)/WhatsApp Image 2023-11-29 at 12.20.57 (2).jpeg" alt="Image" className="zoom-image "   style={{height:"300px"}}/>
                                </div>
                                {/* <div class="class-text">
                        <div class="class-teacher">
                            <img src="img/teacher-6.png" alt="Image"/>
                            <h3>Millie Harper</h3>
                            <a href="">+</a>
                        </div>
                        <h2>Vinyasa yoga</h2>
                        <div class="class-meta">
                            <p><i class="far fa-calendar-alt"></i>Sun, Tue, Thu</p>
                            <p><i class="far fa-clock"></i>9:00 - 10:00</p>
                        </div>
                    </div> */}
                            </div>
                        </div>


                        <div class="col-lg-4 col-md-6 col-sm-12 class-item filter-3 wow fadeInUp" data-wow-delay="1s">
                            <div class="class-wrap">
                                <div class="class-img">
                                    <img src="img/New folder (2)/WhatsApp Image 2023-11-29 at 12.20.57.jpeg" alt="Image" className="zoom-image "  style={{height:"300px"}} />
                                </div>
                                {/* <div class="class-text">
                        <div class="class-teacher">
                            <img src="img/teacher-6.png" alt="Image"/>
                            <h3>Millie Harper</h3>
                            <a href="">+</a>
                        </div>
                        <h2>Vinyasa yoga</h2>
                        <div class="class-meta">
                            <p><i class="far fa-calendar-alt"></i>Sun, Tue, Thu</p>
                            <p><i class="far fa-clock"></i>9:00 - 10:00</p>
                        </div>
                    </div> */}
                            </div>
                        </div>
     


                        <div class="col-lg-4 col-md-6 col-sm-12 class-item filter-3 wow fadeInUp" data-wow-delay="1s">
                            <div class="class-wrap">
                                <div class="class-img">
                                    <img src="img/New folder (2)/WhatsApp Image 2023-11-29 at 12.20.26.jpeg" alt="Image" className="zoom-image "  style={{height:"300px"}} />
                                </div>
                                {/* <div class="class-text">
                        <div class="class-teacher">
                            <img src="img/teacher-6.png" alt="Image"/>
                            <h3>Millie Harper</h3>
                            <a href="">+</a>
                        </div>
                        <h2>Vinyasa yoga</h2>
                        <div class="class-meta">
                            <p><i class="far fa-calendar-alt"></i>Sun, Tue, Thu</p>
                            <p><i class="far fa-clock"></i>9:00 - 10:00</p>
                        </div>
                    </div> */}
                            </div>
                        </div>


                        <div class="col-lg-4 col-md-6 col-sm-12 class-item filter-3 wow fadeInUp" data-wow-delay="1s">
                            <div class="class-wrap">
                                <div class="class-img">
                                    <img src="img/New folder (2)/11.jpeg" alt="Image" className="zoom-image "   style={{height:"300px"}}/>
                                </div>
                                {/* <div class="class-text">
                        <div class="class-teacher">
                            <img src="img/teacher-6.png" alt="Image"/>
                            <h3>Millie Harper</h3>
                            <a href="">+</a>
                        </div>
                        <h2>Vinyasa yoga</h2>
                        <div class="class-meta">
                            <p><i class="far fa-calendar-alt"></i>Sun, Tue, Thu</p>
                            <p><i class="far fa-clock"></i>9:00 - 10:00</p>
                        </div>
                    </div> */}
                            </div>
                        </div>




                    </div>
                </div>
            </div>




            <div class="class">
                <div class="container">
                    <div class="section-header text-center wow zoomIn" data-wow-delay="0.1s">
                        <p>Our </p>
                        <h2  >Videos</h2>
                    </div>
                    <div class="row">
                        <div class="col-12">
                            {/* <ul id="class-filter">
                    <li data-filter="*" class="filter-active">All Classes</li>
                    <li data-filter=".filter-1">Pilates Yoga</li>
                    <li data-filter=".filter-2">Hatha Yoga</li>
                    <li data-filter=".filter-3">Vinyasa yoga</li>
                </ul> */}
                        </div>
                    </div>
                    <div class="row class-container">

                        <div className="col-lg-4 col-md-6 col-sm-12 class-item filter-1 wow fadeInUp" data-wow-delay="0.0s">
                            <div className="class-wrap">
                                <video controls style={{ width: "100%", height: "auto" }}>
                                    <source src="img1/video2 (online-video-cutter.com).mp4" type="video/mp4" />
                                    Your browser does not support the video tag.
                                </video>
                            </div>
                        </div>



                        <div className="col-lg-4 col-md-6 col-sm-12 class-item filter-1 wow fadeInUp" data-wow-delay="0.0s">
                            <div className="class-wrap">
                                <video controls style={{ width: "100%", height: "auto" }}>
                                    <source src="img/New folder (2)/video 11_cropped.mp4" type="video/mp4" />
                                    Your browser does not support the video tag.
                                </video>
                            </div>
                        </div>
                        <div className="col-lg-4 col-md-6 col-sm-12 class-item filter-1 wow fadeInUp" data-wow-delay="0.0s">
                            <div className="class-wrap">
                                <video controls style={{ width: "100%", height: "400px" }}>
                                    <source src="img/New folder (2)/video15.mp4" type="video/mp4" />
                                    Your browser does not support the video tag.
                                </video>
                            </div>
                        </div>










                    </div>
                </div>
            </div>





        </>
    )
}

export default ClassNameName;