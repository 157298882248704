import React,{useEffect} from 'react'

const Team=()=> {
    useEffect(() => {
        window.scrollTo(0, 0); // Scrolls to the top of the page
    }, []);
  return (
    <>
    
    <div className="page-header" >
            <div className="container">
                <div className="row">
                    <div className="col-12">
                        
                        <h2>Teams</h2>
                    </div>
                    <div className="col-12">
                        <a href="">Home</a>
                        <a href="">Teams</a>
                    </div>
                </div>
            </div>
        </div>
    
        {/* <div className="page-header-team">
            <div className="container">
                <div className="row">
                    <div className="col-12">
                        <h2>Trainer</h2>
                    </div>
                    <div className="col-12">
                        <a href="">Home</a>
                        <a href="">Trainer</a>
                    </div>
                </div>
            </div>
        </div> */}

        <div className="team">
            <div className="container">
                <div className="section-header text-center wow zoomIn" data-wow-delay="0.1s">
                    <p>Yoga Trainer</p>
                    <h2>Expert Yoga Trainer</h2>
                </div>
                <div className="row">
                    <div className="col-lg-3 col-md-6 wow fadeInUp" data-wow-delay="0.0s">
                        <div className="team-item">
                            <div className="team-img">
                                <img src="img/team-1.jpg" alt="Image"/>
                                <div className="team-social">
                                    <a href=""><i className="fab fa-twitter"></i></a>
                                    <a href=""><i className="fab fa-facebook-f"></i></a>
                                    <a href=""><i className="fab fa-linkedin-in"></i></a>
                                    <a href=""><i className="fab fa-instagram"></i></a>
                                </div>
                            </div>
                            <div className="team-text">
                                <h2>Millie Harper</h2>
                                <p>Yoga Teacher</p>
                            </div>
                        </div>
                    </div>
                    <div className="col-lg-3 col-md-6 wow fadeInUp" data-wow-delay="0.2s">
                        <div className="team-item">
                            <div className="team-img">
                                <img src="img/team-2.jpg" alt="Image"/>
                                <div className="team-social">
                                    <a href=""><i className="fab fa-twitter"></i></a>
                                    <a href=""><i className="fab fa-facebook-f"></i></a>
                                    <a href=""><i className="fab fa-linkedin-in"></i></a>
                                    <a href=""><i className="fab fa-instagram"></i></a>
                                </div>
                            </div>
                            <div className="team-text">
                                <h2>Lilly Fry</h2>
                                <p>Yoga Teacher</p>
                            </div>
                        </div>
                    </div>
                    <div className="col-lg-3 col-md-6 wow fadeInUp" data-wow-delay="0.4s">
                        <div className="team-item">
                            <div className="team-img">
                                <img src="img/team-3.jpg" alt="Image"/>
                                <div className="team-social">
                                    <a href=""><i className="fab fa-twitter"></i></a>
                                    <a href=""><i className="fab fa-facebook-f"></i></a>
                                    <a href=""><i className="fab fa-linkedin-in"></i></a>
                                    <a href=""><i className="fab fa-instagram"></i></a>
                                </div>
                            </div>
                            <div className="team-text">
                                <h2>Elise Moran</h2>
                                <p>Yoga Teacher</p>
                            </div>
                        </div>
                    </div>
                    <div className="col-lg-3 col-md-6 wow fadeInUp" data-wow-delay="0.6s">
                        <div className="team-item">
                            <div className="team-img">
                                <img src="img/team-4.jpg" alt="Image"/>
                                <div className="team-social">
                                    <a href=""><i className="fab fa-twitter"></i></a>
                                    <a href=""><i className="fab fa-facebook-f"></i></a>
                                    <a href=""><i className="fab fa-linkedin-in"></i></a>
                                    <a href=""><i className="fab fa-instagram"></i></a>
                                </div>
                            </div>
                            <div className="team-text">
                                <h2>Kate Glover</h2>
                                <p>Yoga Teacher</p>
                            </div>
                        </div>
                    </div>
                    <div className="col-lg-3 col-md-6 wow fadeInUp" data-wow-delay="0.8s">
                        <div className="team-item">
                            <div className="team-img">
                                <img src="img/team-1.jpg" alt="Image"/>
                                <div className="team-social">
                                    <a href=""><i className="fab fa-twitter"></i></a>
                                    <a href=""><i className="fab fa-facebook-f"></i></a>
                                    <a href=""><i className="fab fa-linkedin-in"></i></a>
                                    <a href=""><i className="fab fa-instagram"></i></a>
                                </div>
                            </div>
                            <div className="team-text">
                                <h2>Millie Harper</h2>
                                <p>Yoga Teacher</p>
                            </div>
                        </div>
                    </div>
                    <div className="col-lg-3 col-md-6 wow fadeInUp" data-wow-delay="1s">
                        <div className="team-item">
                            <div className="team-img">
                                <img src="img/team-2.jpg" alt="Image"/>
                                <div className="team-social">
                                    <a href=""><i className="fab fa-twitter"></i></a>
                                    <a href=""><i className="fab fa-facebook-f"></i></a>
                                    <a href=""><i className="fab fa-linkedin-in"></i></a>
                                    <a href=""><i className="fab fa-instagram"></i></a>
                                </div>
                            </div>
                            <div className="team-text">
                                <h2>Lilly Fry</h2>
                                <p>Yoga Teacher</p>
                            </div>
                        </div>
                    </div>
                    <div className="col-lg-3 col-md-6 wow fadeInUp" data-wow-delay="1.2s">
                        <div className="team-item">
                            <div className="team-img">
                                <img src="img/team-3.jpg" alt="Image"/>
                                <div className="team-social">
                                    <a href=""><i className="fab fa-twitter"></i></a>
                                    <a href=""><i className="fab fa-facebook-f"></i></a>
                                    <a href=""><i className="fab fa-linkedin-in"></i></a>
                                    <a href=""><i className="fab fa-instagram"></i></a>
                                </div>
                            </div>
                            <div className="team-text">
                                <h2>Elise Moran</h2>
                                <p>Yoga Teacher</p>
                            </div>
                        </div>
                    </div>
                    <div className="col-lg-3 col-md-6 wow fadeInUp" data-wow-delay="1.4s">
                        <div className="team-item">
                            <div className="team-img">
                                <img src="img/team-4.jpg" alt="Image"/>
                                <div className="team-social">
                                    <a href=""><i className="fab fa-twitter"></i></a>
                                    <a href=""><i className="fab fa-facebook-f"></i></a>
                                    <a href=""><i className="fab fa-linkedin-in"></i></a>
                                    <a href=""><i className="fab fa-instagram"></i></a>
                                </div>
                            </div>
                            <div className="team-text">
                                <h2>Kate Glover</h2>
                                <p>Yoga Teacher</p>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </>
  )
}

export default Team;