import React,{useEffect} from 'react'
import { MdKeyboardDoubleArrowRight } from "react-icons/md";

const Naturopathy = () => {
    useEffect(() => {
        window.scrollTo(0, 0); // Scrolls to the top of the page
    }, []);
    return (
        <>
            <div>
                <div className="page-headern" >
                    <div className="container resposnive_breadmcrum">
                        <div className="row">
                            <div className="col-12" style={{fontFamily:"Circular"}}>

                                <h2 >Naturopathy</h2>
                            </div>
                            <div className="col-12" style={{fontFamily:"Circular"}}>
                                <a href="/" >Home</a>
                                <a href="" ><MdKeyboardDoubleArrowRight/>Naturopathy </a>
                            </div>
                        </div>
                    </div>
                </div>
                <div className="about wow fadeInUp" data-wow-delay="0.1s"  style={{marginTop:'-80px'}}>
                    <div className="container">
                        <div className="row align-items-center">
                            <div className="col-lg-6 col-md-6">
                                <div >
                                    <p><br /><br /></p>

                                </div>
                                <div className="about-img about wow fadeInUp nature" data-wow-delay="0.3s">
                                <img src="./img/n3.jpeg" alt="about" style={{ height: "350px",objectFit:'cover',marginTop:'10px'  }} className="move-image" />
                                </div>
                            </div>
                            <div className="col-lg-6 col-md-6">
                                <div className="section-header text-left">
                                    <p>Naturopathy</p>
                                    <h2>Nature cure  </h2><br />

                                </div>
                                <div className="about-text about wow fadeInUp" data-wow-delay="0.6s" style={{marginTop:'-80px'}}>
                                    <p>


                                        It is a holistic system of Medicine works both on Body & Mind wellness.<br />
                                        
                                        <br /> <b>The main principle is it uses panchamahabhutas (5 elements) Akash, Vayu, Agni ,Apa & Prithvi ( space, air, fire, water & earth) </b>
                                        <br />
                                        <br />
                                        
                                        Nature cure uses simple, holistic methods of treatments  such as food, hydrotherapy, massage & various packs
                                        <br />

                                        <b>"Let Nature be the medicine, and let medicine be the Nature”. </b>
                                        <br />
                                        At <b style={{color:"black"}}>AnantaSaukhyam</b>, we not only aim treating the diseases.
                                        We aim at treating the disease from root cause & reverse the condition..




                                    </p>
                                    {/* <p>
                                        At Ananta Saukhyam we treat the root cause of the disease & help the body to rewrite it's original State of health.
                                        We Understand diet & Nutrition not from theory point view but  from Individual requirement based on their Prakriti, lifestyle, purpose & there activities. Individual tailor made plans are given to achieve a Healthy Sustainable lifestyle.
                                    </p> */}


                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                {/* <div className="about wow fadeInUp" data-wow-delay="0.1s">
    <div className="container">
        <div className="row align-items-center">
            
            <div className="col-lg-5 col-md-6">
                 <div className="about-text">
                 <p>
                   
                   Counseling Sessions are designed to understand the  Individual Nature ( Swabhava). Helps them to understand there Nature, qualities & remove the myths of Mind. Develop a balanced Emotional well-being & incorporate it in Day to day life to reach  their highest potential 
                   Healthy lifestyle- are designed to understand the circadian rhythm of the Individual & help the person to get the best of there of Life 
                   </p>
                </div>
            </div>
            <div className="col-lg-7 col-md-6">
                <div className="about-img">
                    <img src="img/slider2.jpg" alt="about"/>
                </div>
            </div>
        </div>
    </div>
</div> */}
            </div>

        </>
    )
}

export default Naturopathy
