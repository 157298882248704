import React,{useEffect} from 'react'
import { MdKeyboardDoubleArrowRight } from "react-icons/md";
const Services=()=> {
    useEffect(() => {
        window.scrollTo(0, 0); // Scrolls to the top of the page
    }, []);
  return (
    <>
    <div className="page-headery">
    <div className="container resposnive_breadmcrum">
        <div className="row">
            <div className="col-12">
                <h2>Service</h2>
            </div>
            <div className="col-12">
                <a href="/">Home</a>
                <a href=""><MdKeyboardDoubleArrowRight/>Service</a>
            </div>
        </div>
       </div>
   </div>

<div className="service">
<div className="container">
    <div className="section-header text-center wow zoomIn" data-wow-delay="0.1s">
        <p>What we do</p>
        <h2>Yoga For Health</h2>
    </div>
    <div className="row">
        <div className="col-lg-4 col-md-6 wow fadeInUp" data-wow-delay="0.0s">
            <div className="service-item">
                <div className="service-icon">
                    <i className="flaticon-workout"></i>
                </div>
                <h3>Heal emotions</h3>
                <p>
                   
                </p>
            </div>
        </div>
        <div className="col-lg-4 col-md-6 wow fadeInUp" data-wow-delay="0.2s">
            <div className="service-item active">
                <div className="service-icon">
                    <i className="flaticon-workout-1"></i>
                </div>
                <h3>Body Refreshes</h3>
                <p>
                    Lorem ipsum dolor sit amet elit. Phasellus nec pretium mi. Curabitur facilisis ornare velit non
                </p>
            </div>
        </div>
        <div className="col-lg-4 col-md-6 wow fadeInUp" data-wow-delay="0.4s">
            <div className="service-item">
                <div className="service-icon">
                    <i className="flaticon-workout-2"></i>
                </div>
                <h3>Mind & Serenity</h3>
                <p>
                    Lorem ipsum dolor sit amet elit. Phasellus nec pretium mi. Curabitur facilisis ornare velit non
                </p>
            </div>
        </div>
        <div className="col-lg-4 col-md-6 wow fadeInUp" data-wow-delay="0.6s">
            <div className="service-item">
                <div className="service-icon">
                    <i className="flaticon-workout-3"></i>
                </div>
                <h3>Enjoy Your life</h3>
                <p>
                    Lorem ipsum dolor sit amet elit. Phasellus nec pretium mi. Curabitur facilisis ornare velit non
                </p>
            </div>
        </div>
        <div className="col-lg-4 col-md-6 wow fadeInUp" data-wow-delay="0.8s">
            <div className="service-item">
                <div className="service-icon">
                    <i className="flaticon-workout-4"></i>
                </div>
                <h3>Body & Spirituality</h3>
                <p>
                    Lorem ipsum dolor sit amet elit. Phasellus nec pretium mi. Curabitur facilisis ornare velit non
                </p>
            </div>
        </div>
        <div className="col-lg-4 col-md-6 wow fadeInUp" data-wow-delay="1s">
            <div className="service-item">
                <div className="service-icon">
                    <i className="flaticon-yoga-pose"></i>
                </div>
                <h3>Body & Mind</h3>
                <p>
                    Lorem ipsum dolor sit amet elit. Phasellus nec pretium mi. Curabitur facilisis ornare velit non
                </p>
            </div>
        </div>
    </div>
</div>
</div>
</>


  )
}

export default Services;