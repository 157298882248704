import React from 'react';
import Slider from 'react-slick';
import 'slick-carousel/slick/slick.css';
import 'slick-carousel/slick/slick-theme.css';
import './ReviewSlider.css';
import { Link } from 'react-router-dom'; // Import the custom CSS file

const ReviewSlider = () => {
    const settings = {
        dots: true,
        infinite: true,
        speed: 3000,
        slidesToShow: 1,
        slidesToScroll: 1,
        autoplay: true,
        autoplaySpeed: 1000, // Set the auto-play speed in milliseconds
    };
    const reviews = [
        {
            id: 1,
            author: 'Priyanka Premnath',
            review: 'Dr Swarna came in to my rescue to help with my health when I was struggling with long covid issues. Extremely grateful for this. She was my personal trainer and later on to my mother and we really enjoyed her classes. From a therapy perspective she is very patient and incorporates various techniques which are personalised based on each persons requirements which she herself evaluates. Also some of her alternative therapies have helped me personally I strongly recommend her if you require a personal touch through her therapy sessions.',
            image: '../img1/re1.png',  // Replace with your image URLs
        },
        {
            id: 1,
            author: 'Nagesh Volety',
            review: 'Dr.Swarna is one of the best doctors, I have ever seen, I was panic to see a spike in my HBA1C count as 14, off late realizing that I was diabetic during April 23, When I first met this Angel, she calmed me and give the best method of diet to be adopted WITHOUT ANY MEDICINE; that today after 3 months (June 23) my present reading is 6.4 (by only changing the diet) & which shall reduce further as assured by her. I am free from Diabetic. Let me tell you she is a living goddess at least for me, while she knows, Ayurveda, Home remedial tips,  Naturopathy, Allopathy, Yoga, Meditation & much more.... I feel if someone has referred you or you are reading this simply means, you are here only because of your luck...',
            image: '../img1/re2.png',
            // Replace with your image URLs
        },
        {
            id: 1,
            author: 'Prathima K',
            review: 'Dr.Swarna is an amazing humble person. She thought me yoga during pregnancy also diet during pregnancy which helped me with very easy normal delivery. She is my go to Dr for many natural remidies . I recently took accupunture treatment from her which also helped relieve pain very well, Kudos to her.. Thank you Dr Swarna',
            image: '../img1/re3.png', // Replace with your image URLs
        },
        {
            id: 1,
            author: 'Srimathi Srinivas',
            review: 'Doctor swarna is a thorough professional with exemplary knowledge in naturopathy and yoga sciences. Each body is different and she understands that perfectly while treating. We are lucky to have known her and continue to support her purse cause of treating people with various issues in a systematic and calm approach. Thank you doctor swarna for all you do.',
            image: '../img1/re4.png', // Replace with your image URLs
        },
        // Add more reviews as needed
    ];

    return (
        <div className="container-fluid review">

        <div className="review-slider-container wow fadeInUp" data-wow-delay="0.3s">
            <div className="row mx-0 justify-content-center text-center">
                <div className="section-header text-center wow fadeInUp" data-wow-delay="0.4s">
                    <p>What Our</p>
                    <h2>Client Says..!</h2>
                </div>
            </div>

            <Slider {...settings}>
                {reviews.map((review) => (
                    <Link to={`https://www.google.com/search?q=Dr.+Swarna+Gowri+BNYS+Yoga+and+Naturopathy%2C+Acupuncturist&rlz=1C1CHWL_enIN1002IN1016&oq=Dr.+Swarna+Gowri+&gs_lcrp=EgZjaHJvbWUqBggCEEUYOzIGCAAQRRg5MggIARAAGBYYHjIGCAIQRRg7MgYIAxBFGDsyBggEEEUYPNIBCDQ5OTlqMGo3qAIAsAIA&sourceid=chrome&ie=UTF-8`} key={review.id} className="review-slide wow fadeInUp" data-wow-delay="0.5s">
                        <img src={review.image} alt={`Review by ${review.author}`} />
                        <div className="review-content">
                            <h3>{review.author}</h3>
                            <p style={{color:"black", textAlign:"justify"}}>{review.review}</p>
                        </div>
                    </Link>
                ))}
            </Slider>
        </div>
        </div>
    );
};

export default ReviewSlider;
