import React,{useEffect} from 'react'
import { MdKeyboardDoubleArrowRight } from "react-icons/md";

const Diet = () => {
  useEffect(() => {
    window.scrollTo(0, 0); // Scrolls to the top of the page
}, []);
  return (
    <div>
      <div className="page-headers" >
        <div className="container resposnive_breadmcrum">
          <div className="row">
            <div className="col-12" style={{fontFamily:"Circular"}}>

              <h2 >Diet</h2>
            </div>
            <div className="col-12" style={{fontFamily:"Circular"}}>
              <a href="/" >Home</a>
              <a href="" ><MdKeyboardDoubleArrowRight/>Diet </a>
            </div>
          </div>
        </div>
      </div>
      <div className="about wow fadeInUp" data-wow-delay="0.1s"  style={{marginTop:'-100px'}}>
        <div className="container">
          <div className="row align-items-center">
            <div className="col-lg-6 col-md-6">
              <div >
                <p><br /><br /></p>

              </div>
              <div className="about-img about wow fadeInUp" data-wow-delay="0.3s" style={{marginTop:'-65px'}}>
                <img src="img/diet111.jpg" alt="about" style={{ height: "400px" }} className="move-image" />
              </div>
            </div>
            <div className="col-lg-6 col-md-6">
              <div className="section-header text-left">
                <p>Diet and Food Therapy</p>
                <h2>Food Heals You</h2><br />

              </div>
              <div className="about-text about wow fadeInUp" data-wow-delay="0.6s" style={{marginTop:'-80px'}}>
                <p>


                  Right dietary changes play vital role on <b>Resolving, Reversing and Restoring</b> to a healthy body & Mind  so that it plays a vital role for a Healthy  Life
                  Hippocrates  Father of modern medicine says
                  <b>"Let food be thy medicine, and let medicine be thy food”. </b>
                  This food which suppose to be our medicine/ healer for our healthy life is becoming the prime factor for diseases.



                </p>
                <p>
                  At <b style={{ color: "black" }}>AnantaSaukhyam</b> we treat the root cause of the disease & help the body to rewrite it's original State of health.
                  We understand diet & nutrition not from theory point view but from individual requirement based on their <b>prakriti, lifestyle, purpose & activities</b>. Individual tailor made plans are given to achieve a Healthy Sustainable lifestyle.
                </p>


              </div>
            </div>
          </div>
        </div>
      </div>
      {/* <div className="about wow fadeInUp" data-wow-delay="0.1s">
    <div className="container">
        <div className="row align-items-center">
            
            <div className="col-lg-5 col-md-6">
                 <div className="about-text">
                 <p>
                   
                   Counseling Sessions are designed to understand the  Individual Nature ( Swabhava). Helps them to understand there Nature, qualities & remove the myths of Mind. Develop a balanced Emotional well-being & incorporate it in Day to day life to reach  their highest potential 
                   Healthy lifestyle- are designed to understand the circadian rhythm of the Individual & help the person to get the best of there of Life 
                   </p>
                </div>
            </div>
            <div className="col-lg-7 col-md-6">
                <div className="about-img">
                    <img src="img/slider2.jpg" alt="about"/>
                </div>
            </div>
        </div>
    </div>
</div> */}
    </div>
  )
}

export default Diet
