import React, { useEffect } from "react";
import { MdKeyboardDoubleArrowRight } from "react-icons/md";

const Consultation = () => {
  useEffect(() => {
    window.scrollTo(0, 0); // Scrolls to the top of the page
  }, []);
  return (
    <div>
      <div className="page-headercu">
        <div className="container resposnive_breadmcrum">
          <div className="row">
            <div className="col-12" style={{ fontFamily: "Circular" }}>
              <h2 >Consultation</h2>
            </div>
            <div className="col-12" style={{ fontFamily: "Circular" }}>
              <a href="/">
                Home
              </a>
              <a href="" >
                {" "}
                <MdKeyboardDoubleArrowRight /> Consultation{" "}
              </a>
            </div>
          </div>
        </div>
      </div>
      <div className="about wow fadeInUp" data-wow-delay="0.2s" style={{marginTop:'-20px'}}>
        <div className="container">
          <div className="row align-items-center">
            <div className="col-lg-6 col-md-6">
              <div>
                <p>
                  <br />
                  <br />
                </p>
              </div>
              <div className="about-img  wow fadeInUp" data-wow-delay="0.5s">
                <img
                  src="img/consultation 2.jpg"
                  alt="about"
                  className="move-image"
                  style={{ height: "400px", marginTop: "-90px" }}
                />
              </div>
            </div>
            <div className="col-lg-6 col-md-6">
              <div className="section-header text-left" style={{marginTop:'10px'}}>
                <p> Consultation </p>
                <h2>We Hear You</h2>
              </div>
              <div
                className="about-text about wow fadeInUp"
                data-wow-delay="0.4s"
                 style={{marginTop:'-60px'}}
              >
                <p>
                  At <b style={{ color: "black" }}>AnantaSaukhyam</b>, we
                  understand that each individual is unique, and so is their
                  journey towards health.
                  <br />
                  Our personalized consultation service are designed{" "}
                  <b> to cater to your specific needs and goals,</b> ensuring
                  that you get the most out of this consultation.
                </p>
                <p>
                  <b style={{ color: "black" }}>Why Consultation Matters:</b>
                  <br />
                  <b>
                    At AnantaSaukhyam we treat the root cause of the disease &
                    help the body to restore it's original State of health.{" "}
                  </b>
                  <br />
                  <b></b> A detailed history is taken, analysed and a
                  personalised treatment plan is evaluated which is holistic and
                  healing. It treats the root of the disease, not just symptoms.
                  Where a plan is evaluated to{" "}
                  <b> restore health resolve & reverse* </b> the disease
                  conditions.
                  <br />
                  Reverse - conditions apply.
                </p>
              </div>
            </div>
          </div>
        </div>
      </div>

      {/* <div className="about wow fadeInUp" data-wow-delay="0.3s">
        <div className="container">
            <div className="row align-items-center">
                
                <div className="col-lg-6 col-md-6">
                     <div className="about-text">
                     <p>
                       
                     <b>3. Health Assessment:</b> Your well-being is our top priority. Our consultations include a health assessment to identify any physical or medical conditions that may impact your yoga practice. This information allows us to provide safe and effective guidance.<br />
<b>4. Customized Classes:</b> Once we have a clear picture of your goals and health status, we create a customized class plan that aligns with your needs. Whether you prefer private one-on-one sessions or small group classes, we have options to suit your comfort level.<br />
<b>5. Ongoing Support:</b> Our consultation service isn't a one-time event; it's a continuous process. We provide ongoing support, regular check-ins, and adjustments to your practice as needed. We're here to guide you every step of the way on your yoga journey.<br />
<b>6. Holistic Wellness:</b> Yoga is not just about physical postures; it's a holistic approach to well-being. Our consultation service delves into all aspects of yoga, including breathwork, meditation, and mindfulness. We believe that true wellness encompasses the mind, body, and spirit.<br />
                       </p>
                    </div>
                </div>
                <div className="col-lg-6 col-md-6">
                    <div className="about-img">
                        <img src="img/consultation  3.jpg" alt="about"/>
                    </div>
                </div>
            </div>
        </div>
    </div> */}
    </div>
  );
};

export default Consultation;
