import React,{useEffect} from "react";
import { MdKeyboardDoubleArrowRight } from "react-icons/md";
// import './About.css'
const About = () => {
    useEffect(() => {
        window.scrollTo(0, 0); // Scrolls to the top of the page
    }, []);
  return (
    <>
      <div className="page-headera  " >
        <div className="container resposnive_breadmcrum">
          <div className="row">
            <div className="col-12 ">
              <h2 style={{fontFamily:"Circular"}}>About Us</h2>
            </div>
            <div className="col-12 " style={{fontFamily:"Circular"}}>
              <a href="/">Home</a>
              <a href=""><MdKeyboardDoubleArrowRight/>About Us</a>
            </div>
          </div>
        </div>
      </div>
      
      <div className="about wow fadeInUp abt" data-wow-delay="0.1s"  >
        <div className="container">
          <div className="row align-items-center">
            <div className="col-lg-5 col-md-6">
              <div className="about1">
                <img
                  src="img/swarna111.jpeg"
                  alt="about"
                  // style={{ height: "600px",marginTop:'-180px' }}
                />
              </div>
            </div>
            <div className="col-lg-7 col-md-6">
              <div
                className="section-header text-left about wow fadeInUp txt"
                data-wow-delay="0.5s"
              >
                <br />

                <p>Learn About Us</p>
                <h2>Welcome to AnantaSaukhyam</h2>
                <br />
                <h3>
                  Meet our Co-founder/Director{" "}
                  <b>Dr Swarna Gowri M V (BNYS, PGDHHM)</b> - Senior Yoga &
                  Naturopathy Physician
                </h3>
              </div>
              <div
                className="about-text about wow fadeInUp"
                data-wow-delay="0.7s" style={{marginTop:'-110px'}}
              >
                <p>
                  <b>Dr Swarna Gowri</b> is practicing Yoga & Naturopathy since
                  2003, has over 20 years of experience treating patients using{" "}
                  <b>Naturopathy, Acupuncture, Counselling and Medical Yoga</b>,
                  not only in India, but across the world.
                </p>
                <p>
                  She has also worked extensively with corporates to promote and
                  foster employee well-being.
                </p>
                <p>
                  She specializes in treating disorders such as chronic pain,
                  hypertension, diabetes, high cholesterol, PCOS, infertility,
                  Skin disorders, Allergies , Arthritis, sleep disorders among
                  others. She has worked at <b>Narayana Hrudayalaya</b>, since
                  1st May 2003 to 1st May 2008.
                </p>
                <div className="wow fadeInUp" data-wow-delay="0.9s">
                  <p>
                    <b>Vision  - </b>
                    Having being worked for Corporate Industry for more than a
                    Decade we understand the challenges faced by them. Keeping
                    that in mind <b>AnantaSaukhyam</b> is designed in such a way
                    that it caters the requirement for Healthy lifestyle of an
                    Individual. 
                    {/* <b>
                      Acupuncture, Pressure Therapy, Counseling, Diet &
                      Lifestyle, Yoga Therapy, Ayurveda, Homeopathy
                      Consultations & Advices
                    </b>{" "}
                    will be given through our expertise in the field. */}
                  </p>
                  <p>
                    <b> Mission - </b>
                  Through Holistic/ integrated Health approach &
                    well being of the employees. An Integrated Clinic which
                    caters the demands of today's employees through{" "}
                    <b>
                      Acupuncture, Pressure Therapy, Counseling, Diet &
                      Lifestyle, Yoga Therapy, Ayurveda, Homeopathy
                      Consultations & Advices
                    </b>{" "}
                    will be given through our expertise in the field.
                  </p>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>

      <div className="about wow fadeInUp about-up k1 " data-wow-delay="0.1s"   >
        <div className="container">
          <div className="row align-items-center">
            <div className="col-lg-7 col-md-6">
              <div
                className="about-text about wow fadeInUp about-up1"
                data-wow-delay="0.4s" 
              >
                <p>
                  Her main working area in the Rehabilitation at Narayana
                  Hrudayalaya was to counsel the patients that include mainly
                  cardiac patients like post surgical, post angioplasty and with
                  medical management mainly for stress management and Yogic
                  advice for life style modification She has also worked as
                  Scientist for a{" "}
                  <b>
                    Research project title: Yoga in patients who undergo CABG –A
                    prospective study.
                  </b>
                </p>
                <p>She has worked as a consultant for</p>
                <p>
                  <ul>
                    <li>Sannidi Specialty Center</li>
                    <li>Kaveri Specialty Nursing Home</li>
                    <li>LH Foundation</li>
                    <li>Musigma</li>
                    <li>Cricinfo as a Corporate Yoga Trainer</li>
                    <li>Texas Instruments</li>
                    <li>Analog Devices</li>
                    <li>Qualcomm India Pvt ltd </li>
                  </ul>
                </p>
                <p>
                  <b style={{ color: "black" }}>
                    {" "}
                    She works on a concept reversal of disease conditions and
                    cell memory deletion.
                  </b>
                </p>
                <p>
                  Her interest lies in learning different schools of Yoga and
                  different healing techniques and meditation techniques.{" "}
                  <b>Designing the corporate wellbeing programs.</b>
                </p>
              </div>
            </div>
            <div className="col-lg-5 col-md-6">
              <div className="k1" >
                <img
                  src="img/swarna1.jpeg"
                  alt="about"
                  // style={{ height: "400px" }}
                />
              </div>
            </div>
          </div>
        </div>
      </div>

      <div className="about wow fadeInUp about-up2" data-wow-delay="0.1s" >
        <div className="container">
          <div className="row align-items-center">
            <div className="col-lg-5 col-md-6">
              <div className="k2"  >
                <img src="img/corp 8.jpeg" alt="about" />
              </div>
            </div>
            <div className="col-lg-7 col-md-6">
              <div className="about-text1" >
                <p>
                  <b>
                    {" "}
                    She has an experience on giving talk and had done workshops
                    on various topics related to
                  </b>

                  <ul>
                    <li> Desktop Yoga</li>
                    <li> Emotional Well Being </li>
                    <li> Fertility Health </li>
                    <li> Healthy Lifestyle </li>
                    <li> Know What To Eat </li>
                    <li> Meditation </li>
                    <li> Naturopathy </li>
                    <li> Pregnancy Health </li>
                    <li> Prenatal & Antenatal Care </li>
                    <li> Pressure Threapy For Healthy Living </li>
                    <li> Sessions On Pranayama </li>
                    <li> Spine Health </li>
                    <li> Stress Deletion </li>
                    <li> Women's Health </li>
                    <li> Pediatric Health </li>
                    <li> Geriatric Health </li>
                  </ul>
                </p>
              </div>
            </div>
            <div className="col-lg-12 col-md-6">
              <div className="about-text animated-sentences-container" style={{marginTop:'20px'}}>
                <p>
                  <b>
                    She has extensively given seminors/workshops to Various
                    corporate companies like{" "}
                  </b>
                  <br />

                  <ul className="animated-sentence  wow fadeInUp">
                    <div className=" wow fadeInUp" data-wow-delay="0.1s">
                      {" "}
                      1) ABB - Feb 2016
                      <br />{" "}
                    </div>
                    <div className=" wow fadeInUp" data-wow-delay="0.3s">
                      {" "}
                      2) UTC Aerospace - Feb 2016
                      <br />
                    </div>
                    <div className=" wow fadeInUp" data-wow-delay="0.6s">
                      3) Focus - March 2016
                      <br />{" "}
                    </div>
                    <div className=" wow fadeInUp" data-wow-delay="0.9s">
                      {" "}
                      4) United insurance - 15 June 2016
                      <br />
                    </div>
                    <div className=" wow fadeInUp" data-wow-delay="0.12s">
                      {" "}
                      5) Taj Employees at gateway - 8July 2016
                      <br />
                    </div>
                    <div className=" wow fadeInUp" data-wow-delay="0.15s">
                      6) ZTE Telecommunications - 22 July 2016
                      <br />{" "}
                    </div>
                    <div className=" wow fadeInUp" data-wow-delay="0.18s">
                      {" "}
                      7) Yahoo - 28 July 2016
                      <br />
                    </div>
                    <div className=" wow fadeInUp" data-wow-delay="0.21s">
                      8) Throtom - 26 Aug 2016
                      <br />{" "}
                    </div>
                    <div className=" wow fadeInUp" data-wow-delay="0.244s">
                      {" "}
                      9) Yahoo - 7 Sept 2016
                      <br />
                    </div>
                    <div className=" wow fadeInUp" data-wow-delay="0.27s">
                      {" "}
                      10) Ess & Ess developers at Mysuru - 12 Sept 2016
                      <br />{" "}
                    </div>
                    <div className=" wow fadeInUp" data-wow-delay="0.30s">
                      11) Global consumer Solutions - 18 Oct 2016
                      <br />{" "}
                    </div>
                    <div className=" wow fadeInUp" data-wow-delay="0.33s">
                      12) DNA entertainment Pvt Ltd - 8 March 2017
                      <br />{" "}
                    </div>
                    <div className=" wow fadeInUp" data-wow-delay="0.35s">
                      {" "}
                      13) Star Mach (Mach clearing solutions) - 8 March 2017
                      <br />
                    </div>
                    <div className=" wow fadeInUp" data-wow-delay="0.39s">
                      {" "}
                      14) Kronos - 21 June 2017
                      <br />{" "}
                    </div>
                    <div className=" wow fadeInUp" data-wow-delay="0.44s">
                      {" "}
                      15) Volvo - 21 June 2017
                      <br />{" "}
                    </div>
                    <div className=" wow fadeInUp" data-wow-delay="0.49s">
                      16) Doonas - 14 July 2017
                      <br />{" "}
                    </div>
                    <div className=" wow fadeInUp" data-wow-delay="0.53s">
                      17) Sygene (BIOCON) - 1Aug 2017
                      <br />{" "}
                    </div>
                    <div className=" wow fadeInUp" data-wow-delay="0.58s">
                      {" "}
                      18) Knolskape - 13 Aug 2017
                      <br />
                    </div>
                    <div className=" wow fadeInUp" data-wow-delay="0.60s">
                      {" "}
                      19) Arcadis - 12 Dec 2017
                      <br />{" "}
                    </div>
                    <div className=" wow fadeInUp" data-wow-delay="0.65s">
                      {" "}
                      20) Sage Intacct - 20 Jan 2018 <br />
                    </div>
                    <div className=" wow fadeInUp" data-wow-delay="0.65s">
                      {" "}
                      21) One Advanced - 28 Jun 2023 & many more <br />
                    </div>
                    <div> </div>
                  </ul>
                </p>
              </div>
            </div>
          </div>
        </div>
      </div>

      {/* <div className="team">
    <div className="container">
        <div className="section-header text-center wow zoomIn" data-wow-delay="0.1s">
            <p>Yoga Trainer</p>
            <h2>Expert Yoga Trainer</h2>
        </div>
        <div className="row">
            <div className="col-lg-3 col-md-6 wow fadeInUp" data-wow-delay="0.0s">
                <div className="team-item">
                    <div className="team-img">
                        <img src="img/team-1.jpg" alt="Image"/>
                        <div className="team-social">
                            <a href=""><i className="fab fa-twitter"></i></a>
                            <a href=""><i className="fab fa-facebook-f"></i></a>
                            <a href=""><i className="fab fa-linkedin-in"></i></a>
                            <a href=""><i className="fab fa-instagram"></i></a>
                        </div>
                    </div>
                    <div className="team-text">
                        <h2>Millie Harper</h2>
                        <p>Yoga Teacher</p>
                    </div>
                </div>
            </div>
            <div className="col-lg-3 col-md-6 wow fadeInUp" data-wow-delay="0.2s">
                <div className="team-item">
                    <div className="team-img">
                        <img src="img/team-2.jpg" alt="Image"/>
                        <div className="team-social">
                            <a href=""><i className="fab fa-twitter"></i></a>
                            <a href=""><i className="fab fa-facebook-f"></i></a>
                            <a href=""><i className="fab fa-linkedin-in"></i></a>
                            <a href=""><i className="fab fa-instagram"></i></a>
                        </div>
                    </div>
                    <div className="team-text">
                        <h2>Lilly Fry</h2>
                        <p>Yoga Teacher</p>
                    </div>
                </div>
            </div>
            <div className="col-lg-3 col-md-6 wow fadeInUp" data-wow-delay="0.4s">
                <div className="team-item">
                    <div className="team-img">
                        <img src="img/team-3.jpg" alt="Image"/>
                        <div className="team-social">
                            <a href=""><i className="fab fa-twitter"></i></a>
                            <a href=""><i className="fab fa-facebook-f"></i></a>
                            <a href=""><i className="fab fa-linkedin-in"></i></a>
                            <a href=""><i className="fab fa-instagram"></i></a>
                        </div>
                    </div>
                    <div className="team-text">
                        <h2>Elise Moran</h2>
                        <p>Yoga Teacher</p>
                    </div>
                </div>
            </div>
            <div className="col-lg-3 col-md-6 wow fadeInUp" data-wow-delay="0.6s">
                <div className="team-item">
                    <div className="team-img">
                        <img src="img/team-4.jpg" alt="Image"/>
                        <div className="team-social">
                            <a href=""><i className="fab fa-twitter"></i></a>
                            <a href=""><i className="fab fa-facebook-f"></i></a>
                            <a href=""><i className="fab fa-linkedin-in"></i></a>
                            <a href=""><i className="fab fa-instagram"></i></a>
                        </div>
                    </div>
                    <div className="team-text">
                        <h2>Kate Glover</h2>
                        <p>Yoga Teacher</p>
                    </div>
                </div>
            </div>
        </div>
    </div>
</div> */}
    </>
  );
};

export default About;
