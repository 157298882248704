import React, { useEffect } from "react";
import { MdKeyboardDoubleArrowRight } from "react-icons/md";

const CorparateClinic = () => {
  useEffect(() => {
    window.scrollTo(0, 0); // Scrolls to the top of the page
  }, []);
  return (
    <div>
      <div className="page-headerac">
        <div className="container resposnive_breadmcrum">
          <div className="row">
            <div className="col-12" style={{ fontFamily: "Circular" }}>
              <h2 >Integrated Corporate Clinic</h2>
            </div>
            <div className="col-12" style={{ fontFamily: "Circular" }}>
              <a href="/" >
                Home
              </a>
              <a href="#" >
                <MdKeyboardDoubleArrowRight /> Integrated Corporate Clinic
              </a>
            </div>
          </div>
        </div>
      </div>
      <div
        className="about wow fadeInUp"
        data-wow-delay="0.1s"
        style={{ marginTop: "10px" }}
      >
        <div className="container">
          <div className="row align-items-center">
            <div className="col-lg-6 col-md-6">
              <div>
                <p>
                  <br />
                  <br />
                </p>
              </div>
              <div
                className="about-img about wow fadeInUp corporate"
                data-wow-delay="0.3s"
               
              >
                <img
                  src="img/wellnesscorp.jpg"
                  alt="about"
                  className="move-image"
                  style={{  marginTop: "-60px" }}
                />
              </div>
            </div>
            <div className="col-lg-6 col-md-6">
              <div className="section-header text-left">
                <p>Integrated Corporate Clinic</p>
                <h3 >Balancing Health and Career Through Integrated Care</h3>
                <br />
              </div>
              <div
                className="about-text about wow fadeInUp"
                data-wow-delay="0.6s"
                style={{marginTop:'-90px'}}
              >
                <p>
                  In today's demanding corporate landscape, maintaining a
                  healthy work-life balance is a constant challenge. Long hours,
                  high stress, and sedentary routines can take a toll on your
                  physical and mental well-being. At <b>AnantaSaukhyam</b>, we
                  understand these unique pressures and have created an
                  integrated corporate wellness solution tailored for busy
                  professionals like you. Our state-of-the-art clinic offers a
                  comprehensive range of therapies and consultations under one
                  convenient roof, providing a holistic approach to
                  rejuvenation.
                </p>
                <p>
                  Unlock your body's natural healing with acupuncture, alleviate
                  muscle tension through pressure therapy, and gain emotional
                  insights from counseling at <b>AnantaSaukhyam</b>. Our expert
                  practitioners guide you through personalized diet, lifestyle,
                  yoga, ayurveda, and homeopathy programs. Whether you seek pain
                  relief, stress management, or overall vitality, AnantaSaukhyam
                  is your sanctuary to escape daily demands and invest in your
                  well-being. Contact us today to begin your transformative
                  journey towards optimal wellness.
                </p>
                {/* <p>
                  Some scientists believe that the needles cause the body to
                  release endorphins natural painkillers and may boost blood
                  flow and change brain activity. These biochemical changes may
                  stimulate the body's natural healing abilities and promote
                  physical and emotional well-being.
                </p> */}
              </div>
            </div>
          </div>
        </div>
      </div>
      {/* <div className="about wow fadeInUp" data-wow-delay="0.1s">
        <div className="container">
            <div className="row align-items-center">
                
                <div className="col-lg-5 col-md-6">
                     <div className="about-text">
                     <p>
                       
                       Counseling Sessions are designed to understand the  Individual Nature ( Swabhava). Helps them to understand there Nature, qualities & remove the myths of Mind. Develop a balanced Emotional well-being & incorporate it in Day to day life to reach  their highest potential 
                       Healthy lifestyle- are designed to understand the circadian rhythm of the Individual & help the person to get the best of there of Life 
                       </p>
                    </div>
                </div>
                <div className="col-lg-7 col-md-6">
                    <div className="about-img">
                        <img src="img/slider2.jpg" alt="about"/>
                    </div>
                </div>
            </div>
        </div>
    </div> */}
    </div>
  );
};

export default CorparateClinic;
