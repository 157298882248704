import React from "react";
import { Link } from 'react-router-dom';
import '../css/style.css';
import { faVideo } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { FaPhone } from "react-icons/fa6";


const Topheader=()=>{

    const buttonStyle = {
        display: 'inline-block',
  textDecoration: 'none',
  color: '#fff',
  backgroundColor: '#333', // Background color for the button
  fontFamily: 'Open Sans, sans-serif',
  fontSize: '16px',
  padding: '10px 20px',
  border: 'none',
  borderRadius: '5px',
  transition: 'background-color 0.3s, color 0.3s',
  marginTop: '-43px',
        
      
      };
    
      const iconStyle = {
        marginRight: '10px',
      };
    return(

        <>
         <div className="top-bar d-none d-md-block" style={{backgroundColor:"#24444f"}}>
            <div className="container-fluid">
                <div className="row">
                    <div className="col-md-8">
                        <div className="top-bar-left">
                            <div className="text">
                                <i className="far fa-clock"></i>
                                <h2>8:00 - 6:00</h2>
                                <p>Mon - Sat</p>
                            </div>
                            <div className="text">
                                <FaPhone style={{fontSize:"12px",color:"white"}}/>
                                <span></span>
                                <h2 style={{marginLeft:'6px'}}>+91  8050772821</h2>
                                <p>For Appointment</p>
                            </div>
                        </div>
                    </div>
                    
                    <div className="col-md-4">
                    {/* <Link to="/Videoconsultancy" style={buttonStyle}>
        
       Video Consultancy
      </Link> */}
      {/* <Link to="/Videoconsultancy">
                        
                        <button className="learm_more_button" style={{fontFamily: "Open Sans,Sans Serif",marginTop:'1px'}}>
                        <FontAwesomeIcon icon={faVideo} style={iconStyle} />  Video Consultation<span style={{color:"white",fontSize:'15px'}}>*</span>
                        </button>
                        </Link> */}

                        
                        <Link target="_blank" to="https://www.google.com/search?q=Dr.+Swarna+Gowri+BNYS+Yoga+and+Naturopathy%2C+Acupuncturist&rlz=1C1CHWL_enIN1002IN1016&oq=Dr.+Swarna+Gowri+&gs_lcrp=EgZjaHJvbWUqBggCEEUYOzIGCAAQRRg5MggIARAAGBYYHjIGCAIQRRg7MgYIAxBFGDsyBggEEEUYPNIBCDQ5OTlqMGo3qAIAsAIA&sourceid=chrome&ie=UTF-8">
                        {/* <button className="learm_more_button" style={{fontFamily: "Open Sans,Sans Serif"}}>
                        Video Consultation<span style={{color:"white",fontSize:'15px'}}>*</span>
                        </button> */}
                        <button className="learm_more_button" style={{fontFamily: "Open Sans,Sans Serif",marginTop:'0px',marginLeft:'230px'}}>
                            Reviews<span style={{color:"black",fontSize:'15px'}}>*</span>
                        </button>
                        </Link>
                        

                        
                            <div className="social" style={{marginLeft:'10px',marginTop:'-36px'}}>
                                <a href="https://www.youtube.com/watch?v=0VXeYTuFEmk" target="_blank"><i className="fab fa-youtube" ></i></a>
                              
                            </div>
                        </div>
                    </div>
                </div>
            </div>
      </>
    )
}

export default Topheader;