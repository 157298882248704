import React,{useEffect} from 'react'
import { MdKeyboardDoubleArrowRight } from "react-icons/md";
const Seminars = () => {
  useEffect(() => {
    window.scrollTo(0, 0); // Scrolls to the top of the page
}, []);
  return (
    <div>
       <div className="page-headerco" >
        <div className="container resposnive_breadmcrum">
          <div className="row">
            <div className="col-12" style={{fontFamily:"Circular"}}>

              <h2 >Seminars</h2>
            </div>
            <div className="col-12" style={{fontFamily:"Circular"}}>
              <a href="/" >Home</a>
              <a href="" ><MdKeyboardDoubleArrowRight/>Seminars</a>
            </div>
          </div>
        </div>
      </div>
      <div className="about wow fadeInUp" data-wow-delay="0.1s"  style={{marginTop:'-10px'}}>
    <div className="container">
        <div className="row align-items-center">
            <div className="col-lg-6 col-md-6">
            <div >
                    <p><br /><br /></p>
                    
                </div>
                <div className=" seminar">
                <img src="img1/worklifebalance2.png" alt="about"  className="move-image"/>
                </div>
            </div>
            <div className="col-lg-6 col-md-6">
                <div className="section-header text-left" style={{marginTop:'10px'}}>
                    <p>Seminars/Workshop/Talks to corporates</p>
                    <h2>Work Life Balance</h2><br />
                    
                </div>
                <div className="about-text" style={{marginTop:'-40px'}}>
                    <p>
                    Welcome to our Corporate Seminars program, designed to enhance the well-being and productivity of your employees.  we understand the demands and challenges of the corporate world. <br /><br /><b style={{color:"black"}}>Our seminars offer a holistic approach to corporate wellness, focusing on physical health, mental clarity, and stress Deletion..</b><br /> <br />
                    <b>We conduct programs on various topics</b><br />
                    <ul>
                    <li>	Desktop Yoga</li>
                    <li>	Emotional Well Being </li>
                    <li>	Fertility Health </li>
                    <li>	Healthy Lifestyle </li>
                    <li>	Know What To Eat </li>
                    <li>	Meditation </li>
                    <li>	Naturopathy  </li>
                    <li>	Pregnancy Health </li>
                    <li>	Prenatal & Antenatal Care </li>
                    <li>	Pressure Threapy For Healthy Living  </li>
                    <li>	Sessions On Pranayama </li>
                    <li>	Spine Health </li>
                    <li>	Stress Deletion </li>
                    <li>	Women's Health </li>
                    <li>	Pediatric Health </li>
                    <li>	Geriatric Health </li>
                    </ul> 
                    </p>
                   
                   
                </div>
            </div>
        </div>
    </div>
</div>
{/* <div className="about wow fadeInUp" data-wow-delay="0.1s">
    <div className="container">
        <div className="row align-items-center">
            
            <div className="col-lg-5 col-md-6">
                 <div className="about-text">
                 <p>
                   
                   Counseling Sessions are designed to understand the  Individual Nature ( Swabhava). Helps them to understand there Nature, qualities & remove the myths of Mind. Develop a balanced Emotional well-being & incorporate it in Day to day life to reach  their highest potential 
                   Healthy lifestyle- are designed to understand the circadian rhythm of the Individual & help the person to get the best of there of Life 
                   </p>
                </div>
            </div>
            <div className="col-lg-7 col-md-6">
                <div className="about-img">
                    <img src="img/slider2.jpg" alt="about"/>
                </div>
            </div>
        </div>
    </div>
</div> */}
    </div>
  )
}

export default Seminars
