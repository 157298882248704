import React,{useEffect} from 'react';

const Price=()=> {
    useEffect(() => {
        window.scrollTo(0, 0); // Scrolls to the top of the page
    }, []);
  return (
    <>

        <div className="page-header-price">
            <div className="container">
                <div className="row">
                    <div className="col-12">
                        <h2>Price</h2>
                    </div>
                    <div className="col-12">
                        <a href="">Home</a>
                        <a href="">Price</a>
                    </div>
                </div>
            </div>
        </div>

        <div className="price">
            <div className="container">
                <div className="section-header text-center wow zoomIn" data-wow-delay="0.1s">
                    <p>Yoga Package</p>
                    <h2>Yoga Pricing Plan</h2>
                </div>
                <div className="row">
                    <div className="col-md-4 wow fadeInUp" data-wow-delay="0.0s">
                        <div className="price-item">
                            <div className="price-header">
                                <div className="price-title">
                                    <h2>Basic</h2>
                                </div>
                                <div className="price-prices">
                                    <h2><small>$</small>49<span>/ mo</span></h2>
                                </div>
                            </div>
                            <div className="price-body">
                                <div className="price-description">
                                    <ul>
                                        <li>Personal Trainer</li>
                                        <li>Special class</li>
                                        <li>Free Tutorials</li>
                                        <li>Group Training</li>
                                    </ul>
                                </div>
                            </div>
                            <div className="price-footer">
                                <div className="price-action">
                                    <a className="btn" href="">Join Now</a>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className="col-md-4 wow fadeInUp" data-wow-delay="0.3s">
                        <div className="price-item featured-item">
                            <div className="price-header">
                                <div className="price-status">
                                    <span>Popular</span>
                                </div>
                                <div className="price-title">
                                    <h2>Standard</h2>
                                </div>
                                <div className="price-prices">
                                    <h2><small>$</small>99<span>/ mo</span></h2>
                                </div>
                            </div>
                            <div className="price-body">
                                <div className="price-description">
                                    <ul>
                                        <li>Personal Trainer</li>
                                        <li>Special class</li>
                                        <li>Free Tutorials</li>
                                        <li>Group Training</li>
                                    </ul>
                                </div>
                            </div>
                            <div className="price-footer">
                                <div className="price-action">
                                    <a className="btn" href="">Join Now</a>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className="col-md-4 wow fadeInUp" data-wow-delay="0.6s">
                        <div className="price-item">
                            <div className="price-header">
                                <div className="price-title">
                                    <h2>Premium</h2>
                                </div>
                                <div className="price-prices">
                                    <h2><small>$</small>149<span>/ mo</span></h2>
                                </div>
                            </div>
                            <div className="price-body">
                                <div className="price-description">
                                    <ul>
                                        <li>Personal Trainer</li>
                                        <li>Special class</li>
                                        <li>Free Tutorials</li>
                                        <li>Group Training</li>
                                    </ul>
                                </div>
                            </div>
                            <div className="price-footer">
                                <div className="price-action">
                                    <a className="btn" href="">Join Now</a>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
        <div className="discount wow zoomIn" data-wow-delay="0.1s" style={{"margin-bottom": "90px;"}}>
            <div className="container">
                <div className="section-header text-center">
                    <p>Awesome Discount</p>
                    <h2>Get <span>30%</span> Discount for all classes</h2>
                </div>
                <div className="container discount-text">
                    <p>
                        Lorem ipsum dolor sit amet, consectetur adipiscing elit. Phasellus nec pretium mi. Curabitur facilisis ornare velit non vulputate. Aliquam metus tortor, auctor id gravida condimentum, viverra quis sem. Curabitur non nisl nec nisi scelerisque maximus. 
                    </p>
                    <a className="btn">Join Now</a>
                </div>
            </div>
        </div>
        <div className="blog-img">
                    <img src="img/blog-1.jpg" alt="Blog"/>
                </div>
    
    </>
  )
}



export default Price;