import React from 'react'
import { Link } from 'react-router-dom';

const Services = () => {
    return (
        <div class="service">
            <br />
            <br />

            <div class="container">
                <div class="section-header text-center wow zoomIn" data-wow-delay="0.1s">
                    <p>What we do</p>
                    <h2 style={{color:"#343148"}}>@AnantaSaukhyam</h2>
                </div>
                <div class="row">
                    
                    <div class="col-lg-4 col-md-6 wow fadeInUp" data-wow-delay="0.0s">
                    <Link to="/naturopathy">
                        <div class="service-item">
                            <div class="service-icon">

                                {/* <img src='img/acupunctre 2.jpg'/> */}
                                <i ><img src='img1/as1.jpg' style={{ height: "90px", width: "90px", borderRadius: 200 / 2 }} /></i>
                            </div>
                            <br />
                            <h3>Naturopathy</h3>
                            <p>
                                We use simple methods of Life style modification with food,simple herbs   water  therapy,  balance  the thermodynamics of
                                the Body.
                            </p>
                        </div>
                        </Link>
                    </div>

                    <div class="col-lg-4 col-md-6 wow fadeInUp" data-wow-delay="0.2s">
                    <Link to="/yoga">
                        <div class="service-item ">
                            <div class="service-icon">
                                <i ><img src='img1/as2.jpg' style={{ height: "90px", width: "90px", borderRadius: 200 / 2 }} /></i>
                            </div>
                            <br />
                            <h3>Yoga</h3>
                            <p>
                                Yoga is designed to experience the YOGA (oneness) with the self.
                                Yoga is just not Asanas as generally understood, Medical Yoga is for all the health conditions.



                            </p>
                        </div>
                        </Link>
                    </div>

                    <div class="col-lg-4 col-md-6 wow fadeInUp" data-wow-delay="0.4s">
                    <Link to="/counselleing">
                        <div class="service-item">
                            <div class="service-icon">
                                <i ><img src='img1/as4.jpg' style={{ height: "90px", width: "90px", borderRadius: 200 / 2 }} /></i>
                            </div>
                            <br />
                            <h3>Counseling</h3>
                            <p>
                            The Power of Mind - Understanding the mind & its levels of operations. These sessions are designed to help to create a space that’s required through deletion, restart, uninstall & install.
                            </p>
                        </div>
                        </Link>
                    </div>


                    <div class="col-lg-2 col-md-6 " data-wow-delay="0.6s">
                        <div class="">
                            <div class="">
                                <i ><img src='' style={{ height: "90px", width: "90px", borderRadius: 200 / 2 }} /></i>
                            </div>
                            <br />
                            <h3></h3>
                            {/* <p>
                            Liquid and solid foods regularly consumed in normal living. 2. A prescribed allowance of food adapted for a particular state of health or disease.
                            </p> */}
                        </div>
                    </div>

                    <div class="col-lg-4 col-md-6 wow fadeInUp" data-wow-delay="0.6s">
                    <Link to="/diet">
                        <div class="service-item">
                            <div class="service-icon">
                                <i ><img src='img1/as5.jpg' style={{ height: "90px", width: "90px", borderRadius: 200 / 2 }} /></i>
                            </div>
                            <br />
                            <h3>Diet & Nutrition</h3>
                            <p>
                                Balanced food with proper Nutrition when taken under guidence helps in healing and get back to normal health.
                            </p>
                        </div>
                        </Link>
                    </div>

                    <div class="col-lg-4 col-md-6 wow fadeInUp" data-wow-delay="0.8s">
                    <Link to="/acupenture">
                        <div class="service-item">
                            <div class="service-icon">
                                <i ><img src='img1/as3.jpg' style={{ height: "90px", width: "90px", borderRadius: 200 / 2 }} /></i>
                            </div>
                            <br />
                            <h3>Acupuncture</h3>
                            <p>
                                Acupuncture helps in pain relief, stress reduction, enhanced energy, improved sleep, boosted immunity, non-invasive and safe, experienced practitioners, begin your healing journey.
                            </p>
                        </div>
                        </Link>
                    </div>

                    <div class="col-lg-2 col-md-6 " data-wow-delay="0.6s">
                        <div class="">
                            <div class="">
                                <i ><img src='' style={{ height: "90px", width: "90px", borderRadius: 200 / 2 }} /></i>
                            </div>
                            <br />
                            <h3></h3>
                            {/* <p>
                            Liquid and solid foods regularly consumed in normal living. 2. A prescribed allowance of food adapted for a particular state of health or disease.
                            </p> */}
                        </div>
                    </div>

                    {/* <div class="col-lg-4 col-md-6 wow fadeInUp" data-wow-delay="1s">
                        <div class="service-item">
                            <div class="service-icon">
                            <i ><img src='img1/as1.jpg' style={{height:"90px",width:"90px", borderRadius: 200 / 2}}/></i>
                            </div>
                            <h3>Body & Mind</h3>
                            <p>
                            an approach to understand the relationship between the human body and mind where they are seen as a single integrated unit
                            </p>
                        </div>
                    </div> */}
                </div>
            </div>
        </div>
    )
}

export default Services