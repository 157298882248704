import React from "react";
import { Link } from "react-router-dom";
//import '../css/style.css';
// import '../lib/animate/animate.css';

// import  {define,useEffect} from "react";
// import $ from 'jquery';
// import 'jquery-ui-dist/jquery-ui';
//import WOW from 'wow.js';

// import $ from 'jquery';
// import "jquery-ui-dist/jquery-ui";

// import { useEffect } from "react";
// import '../lib/flaticon/font/flaticon.css';
// import '../lib/owlcarousel/assets/owl.carousel.min.css';
// import '../lib/lightbox/css/lightbox.min.css';

const About = () => {
  //     useEffect=()=>{

  //},[])

  return (
    <>
      <div className="about wow fadeInUp" data-wow-delay="0.2s " style={{marginTop:'-50px'}} >
        <div className="container">
          <div className="row align-items-center about wow fadeInUp">
            <div className="col-lg-5 col-md-6">
              <div className="about-img about-img1 ">
                <Link to="">
                  <img
                    src="img/yogaa12.jpg"
                    alt="yoga"
                    style={{ height: "400px" }}
                  />
                </Link>
              </div>
            </div>
            <div className="col-lg-7 col-md-6">
              <div
                className="section-header text-left"
                style={{ marginTop: "10px" }}
              >
                <p>Learn About Us</p>
                <h2>Welcome to AnantaSaukhyam</h2>
              </div>
              <div
                className="about-text about wow fadeInUp"
                data-wow-delay="0.5s" style={{marginTop:'-120px'}}
              >
                <br />
                <br />

                <p style={{ color: "black" }}>
                  <b>AnantaSaukhyam</b> aims to bring Health to All with the
                  main objective of Spreading the message that All can Lead a
                  Healthy life which brings <b>QUALITY TO HUMAN LIFE. </b>
                  To reach ones own highest potential. Know your Unlimited
                  Source. Experience infinity (the Ananta) in <b>YOU.</b>
                </p>
                <p style={{ color: "black" }}>
                  <b>Vision  -</b> Having being worked for Corporate
                  Industry for more than a Decade we understand the challenges
                  faced by them. Keeping that in mind AnantaSaukhyam is designed
                  in such a way that it caters the requirement for Healthy
                  lifestyle of an Individual. 
                  {/* <b>
                    Acupuncture, Pressure Therapy, Counseling, Diet & Lifestyle,
                    Yoga Therapy, Ayurveda, Homeopathy Consultations & Advices
                  </b>{" "}
                  will be given through our expertise in the field. */}
                </p>
                <p style={{ color: "black" }}>
                  <b> Mission -</b>  Through Holistic/ integrated
                  Health approach & well being of the employees. An Integrated
                  Clinic which caters the demands of today's employees through{" "}
                  <b>
                    Acupuncture, Pressure Therapy, Counseling, Diet & Lifestyle,
                    Yoga Therapy, Ayurveda, Homeopathy Consultations & Advices
                  </b>{" "}
                  will be given through our expertise in the field.
                </p>
                <a className="btn" href="/about">
                  Learn More
                </a>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default About;
