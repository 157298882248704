import React, { useEffect } from "react";
import Class from "../components/Class";
import { MdKeyboardDoubleArrowRight } from "react-icons/md";

const Yoga = () => {
  useEffect(() => {
    window.scrollTo(0, 0); // Scrolls to the top of the page
  }, []);
  return (
    <>
      <div className="page-headery">
        <div className="container resposnive_breadmcrum">
          <div className="row">
            <div className="col-12" style={{ fontFamily: "Circular" }}>
              <h2 >Yoga</h2>
            </div>
            <div className="col-12" style={{ fontFamily: "Circular" }}>
              <a href="/" >
                Home
              </a>
              <a href="" >
                <MdKeyboardDoubleArrowRight />
                Yoga
              </a>
            </div>
          </div>
        </div>
      </div>
      <div className=" wow fadeInUp yogatxt" data-wow-delay="0.3s" >
        <div className="container">
          <div className="row align-items-center">
            <div className="col-lg-6 col-md-6 ">
              <div className="about-img yoga">
                <img
                  src="img/c yoga 5.jpg "
                  alt="about"
                  data-wow-delay="0.1s"
                  className="wow fadeInUp move-image"
                />
              </div>
            </div>
            <div className="col-lg-6 col-md-6">
              <div className="section-header text-left ">
                <p>YOGA</p>
                <h2>Experience Oneness</h2>
                <br />
              </div>
              <div
                className="about-text about wow fadeInUp"
                data-wow-delay="0.4s"
                style={{ marginTop: "-100px" }}
              >
                <p>
                  <b style={{ color: "black" }}>
                    Yoga is designed to experience the YOGA (oneness) with the
                    SELF.
                  </b>
                  <br />
                  Yoga is not just a form of exercise, it is a holistic approach
                  to well-being that encompasses physical, mental, and spiritual
                  health. One of the fundamental principles of yoga is the
                  promotion of a healthy lifestyle.{" "}
                  <b>
                    {" "}
                    Yoga is just not Asanas as generally understood, Medical
                    Yoga is for all the health conditions..
                  </b>
                  <br />
                  <br />
                  <b style={{ color: "black" }}>The Yoga Approach:</b>
                  <br />
                  <br />
                  <b>• Stress-Relief Asanas:</b> Our yoga instructors will teach
                  you a series of gentle and restorative yoga poses that help
                  release tension from the body, reduce muscle stiffness, and
                  promote relaxation.
                  <br />
                  <b>• Breath Awareness:</b> Learn to harness the power of your
                  breath to calm the mind and reduce anxiety. Pranayama will be
                  an integral part of this program
                  <br />
                  <b>• Meditation and Mindfulness:</b> Explore meditation
                  techniques that enhance your ability to stay present,
                  cultivate inner peace, and let go of stress-inducing thoughts
                </p>
                <p>
                  <b>Here at AnantaSaukhyam we undertake </b>
                  <br />
                  Embarking on a yoga journey can be an enriching and
                  transformative experience. However, it's essential to start on
                  the right path and have a clear understanding of your goals.
                  This is where our consultation service comes in:
                  <br />
                </p>
              </div>
            </div>
          </div>
        </div>
      </div>
      <br />
      <br />

      <div
        className="about wow fadeInUp"
        data-wow-delay="0.1s"
        style={{ marginTop: "-60px" }}
      >
        <div className="container">
          <div className="row align-items-center">
            <div className="col-lg-6 col-md-6">
              <div className="about-text  wow fadeInUp" data-wow-delay="0.5s">
                <p>
                  <b>1. Medical Yoga:</b> Medical Yoga for all the disease
                  conditions like Arthritis, bronchial asthma, back pain,
                  hypertension, diabetes, migraine, anxiety depression, rhythm
                  disturbances to name few .<br />
                  <b>2. Corporate Yoga:</b> Corporate Yoga sessions to enhance
                  the well being , bring quality ,able to bring work life
                  balance. The programs/ session are tailor made based on the
                  requirement.
                  <br />
                  <b>3. Desktop Yoga:</b> Desktop Yoga is a session designed to
                  stretch at desk. Experience the relaxation to bring efficiency
                  at the work. It enhances the quality of work . Helps in
                  handling the work pressure & enhances the overall performance,
                  by increased concentration,awareness, & improved cognition &
                  hand eye co ordination .<br />
                  <b>4. Fertility Yoga:</b> Fertility Yoga is one of the
                  expertise of Ananatasaukhyam along with integrated approach is
                  fertility yoga male & female infertility .<br />
                  <b>5. Prenatal Yoga:</b> Prenatal Yoga is designed keeping the
                  challenges faced during each trimeste.special breathing
                  techniques are taught for easy normal delivery .<br />
                  <b>6. Postnatal Yoga:</b> Postnatal Yoga is designed to cater
                  the Mother & helps in her recovery. Brings he hormone balance
                  .<br />
                  <b>7. Women's Health:</b>Women's Health caters for the
                  different demands that are faced in women's health that
                  includes PMS, PCOS, Dysmenorrhea, Fibroids, Hormone
                  Imbalances, Menopause etc..
                  <br />
                </p>
              </div>
            </div>
            <div className="col-lg-6 col-md-6">
              <div className="about-img">
                <img
                  src="img1/IMG_20171201_083517.jpg"
                  alt="about"
                  className="move-image"
                  style={{ height: "540px" }}
                />
              </div>
            </div>
          </div>
        </div>
      </div>
      <Class />
      {/* <div class="container-fluid px-0 py-5 my-5">
                <div class="row mx-0 justify-content-center text-center">
                    <div class="col-lg-6">
                        <h6 class="d-inline-block bg-light text-primary text-uppercase py-1 px-2">Our Service</h6>
                        <h1></h1>
                    </div>
                </div>
                <div class="owl-carousel service-carousel owl-loaded owl-drag">
                    <div class="owl-stage-outer"><div class="owl-stage" style={{ transform: "translate3d(-1618px, 0px, 0px)", transition: "all 1.5s ease 0s", width: "4317px" }}>
                        <div class="owl-item cloned" style={{ width: "269.8px" }}>
                            <div class="service-item position-relative">
                                <img class="img-fluid" src="img1/service-2.jpg" alt="" />
                                <div class="service-text text-center">
                                    <h4 class="text-white font-weight-medium px-3">Accupenture</h4>
                                    <p class="text-white px-3 mb-3">:  Acupuncture is an age-old healing form of alternative medicine and a component practice of traditional Chinese medicine (TCM) </p>
                                    <div class="w-100 bg-white text-center p-4">
                                        <a class="btn btn-primary" href="">Make Order</a>
                                    </div>
                                </div>
                            </div></div><div class="owl-item cloned" style={{ width: "269.8px" }}><div class="service-item position-relative">
                                <img class="img-fluid" src="img1/service-3.jpg" alt="" />
                                <div class="service-text text-center">
                                    <h4 class="text-white font-weight-medium px-3">Facial Therapy</h4>
                                    <p class="text-white px-3 mb-3">Elitr labore sit dolor erat est lorem diam sea ipsum diam dolor duo sit ipsum</p>
                                    <div class="w-100 bg-white text-center p-4">
                                        <a class="btn btn-primary" href="">Make Order</a>
                                    </div>
                                </div>
                            </div></div><div class="owl-item cloned" style={{ width: "269.8px" }}><div class="service-item position-relative">
                                <img class="img-fluid" src="img1/service-4.jpg" alt="" />
                                <div class="service-text text-center">
                                    <h4 class="text-white font-weight-medium px-3">Skin Care</h4>
                                    <p class="text-white px-3 mb-3">Elitr labore sit dolor erat est lorem diam sea ipsum diam dolor duo sit ipsum</p>
                                    <div class="w-100 bg-white text-center p-4">
                                        <a class="btn btn-primary" href="">Make Order</a>
                                    </div>
                                </div>
                            </div></div><div class="owl-item cloned" style={{ width: "269.8px" }}><div class="service-item position-relative">
                                <img class="img-fluid" src="img1/service-5.jpg" alt="" />
                                <div class="service-text text-center">
                                    <h4 class="text-white font-weight-medium px-3">Stream Bath</h4>
                                    <p class="text-white px-3 mb-3">Elitr labore sit dolor erat est lorem diam sea ipsum diam dolor duo sit ipsum</p>
                                    <div class="w-100 bg-white text-center p-4">
                                        <a class="btn btn-primary" href="">Make Order</a>
                                    </div>
                                </div>
                            </div></div><div class="owl-item cloned" style={{ width: "269.8px" }}><div class="service-item position-relative">
                                <img class="img-fluid" src="img1/service-6.jpg" alt="" />
                                <div class="service-text text-center">
                                    <h4 class="text-white font-weight-medium px-3">Face Masking</h4>
                                    <p class="text-white px-3 mb-3">Elitr labore sit dolor erat est lorem diam sea ipsum diam dolor duo sit ipsum</p>
                                    <div class="w-100 bg-white text-center p-4">
                                        <a class="btn btn-primary" href="">Make Order</a>
                                    </div>
                                </div>
                            </div></div><div class="owl-item" style={{ width: "269.8px" }}><div class="service-item position-relative">
                                <img class="img-fluid" src="img1/service-1.jpg" alt="" />
                                <div class="service-text text-center">
                                    <h4 class="text-white font-weight-medium px-3">Body Massage</h4>
                                    <p class="text-white px-3 mb-3">Elitr labore sit dolor erat est lorem diam sea ipsum diam dolor duo sit ipsum</p>
                                    <div class="w-100 bg-white text-center p-4">
                                        <a class="btn btn-primary" href="">Make Order</a>
                                    </div>
                                </div>
                            </div></div><div class="owl-item active" style={{ width: "269.8px" }}><div class="service-item position-relative">
                                <img class="img-fluid" src="img1/service-2.jpg" alt="" />
                                <div class="service-text text-center">
                                    <h4 class="text-white font-weight-medium px-3">Accupenture</h4>
                                    <p class="text-white px-3 mb-3">Acupuncture is an age-old healing form of alternative medicine and a component practice of traditional Chinese medicine (TCM)</p>
                                    <div class="w-100 bg-white text-center p-4">
                                        <a class="btn btn-primary" href="">Make Order</a>
                                    </div>
                                </div>
                            </div></div><div class="owl-item active" style={{ width: "269.8px" }}><div class="service-item position-relative">
                                <img class="img-fluid" src="img1/service-3.jpg" alt="" />
                                <div class="service-text text-center">
                                    <h4 class="text-white font-weight-medium px-3">Diet</h4>
                                    <p class="text-white px-3 mb-3">the kind and amount of food selected for a person or animal for a special reason (as improving health)</p>
                                    <div class="w-100 bg-white text-center p-4">
                                        <a class="btn btn-primary" href="">Make Order</a>
                                    </div>
                                </div>
                            </div></div><div class="owl-item active" style={{ width: "269.8px" }}><div class="service-item position-relative">
                                <img class="img-fluid" src="img1/service-4.jpg" alt="" />
                                <div class="service-text text-center">
                                    <h4 class="text-white font-weight-medium px-3">Healthy Lifestyle</h4>
                                    <p class="text-white px-3 mb-3">Health is wealth and is more important than money. Having good health is a synonym for being physically and mentally fit.</p>
                                    <div class="w-100 bg-white text-center p-4">
                                        <a class="btn btn-primary" href="">Make Order</a>
                                    </div>
                                </div>
                            </div></div><div class="owl-item active" style={{ width: "269.8px" }}><div class="service-item position-relative">
                                <img class="img-fluid" src="img1/service-5.jpg" alt="" />
                                <div class="service-text text-center">
                                    <h4 class="text-white font-weight-medium px-3">Stress deletion</h4>
                                    <p class="text-white px-3 mb-3">Elitr labore sit dolor erat est lorem diam sea ipsum diam dolor duo sit ipsum</p>
                                    <div class="w-100 bg-white text-center p-4">
                                        <a class="btn btn-primary" href="">Make Order</a>
                                    </div>
                                </div>
                            </div></div><div class="owl-item active" style={{ width: "269.8px" }}><div class="service-item position-relative">
                                <img class="img-fluid" src="img1/service-6.jpg" alt="" />
                                <div class="service-text text-center">
                                    <h4 class="text-white font-weight-medium px-3">Training on yoga</h4>
                                    <p class="text-white px-3 mb-3">Elitr labore sit dolor erat est lorem diam sea ipsum diam dolor duo sit ipsum</p>
                                    <div class="w-100 bg-white text-center p-4">
                                        <a class="btn btn-primary" href="">Make Order</a>
                                    </div>
                                </div>
                            </div></div><div class="owl-item cloned" style={{ width: "269.8px" }}><div class="service-item position-relative">
                                <img class="img-fluid" src="img1/service-1.jpg" alt="" />
                                <div class="service-text text-center">
                                    <h4 class="text-white font-weight-medium px-3">Body Massage</h4>
                                    <p class="text-white px-3 mb-3">Elitr labore sit dolor erat est lorem diam sea ipsum diam dolor duo sit ipsum</p>
                                    <div class="w-100 bg-white text-center p-4">
                                        <a class="btn btn-primary" href="">Make Order</a>
                                    </div>
                                </div>
                            </div></div><div class="owl-item cloned" style={{ width: "269.8px" }}><div class="service-item position-relative">
                                <img class="img-fluid" src="img1/service-2.jpg" alt="" />
                                <div class="service-text text-center">
                                    <h4 class="text-white font-weight-medium px-3">Stone Therapy</h4>
                                    <p class="text-white px-3 mb-3">Elitr labore sit dolor erat est lorem diam sea ipsum diam dolor duo sit ipsum</p>
                                    <div class="w-100 bg-white text-center p-4">
                                        <a class="btn btn-primary" href="">Make Order</a>
                                    </div>
                                </div>
                            </div></div><div class="owl-item cloned" style={{ width: "269.8px" }}><div class="service-item position-relative">
                                <img class="img-fluid" src="img1/service-3.jpg" alt="" />
                                <div class="service-text text-center">
                                    <h4 class="text-white font-weight-medium px-3">Facial Therapy</h4>
                                    <p class="text-white px-3 mb-3">Elitr labore sit dolor erat est lorem diam sea ipsum diam dolor duo sit ipsum</p>
                                    <div class="w-100 bg-white text-center p-4">
                                        <a class="btn btn-primary" href="">Make Order</a>
                                    </div>
                                </div>
                            </div></div><div class="owl-item cloned" style={{ width: "269.8px" }}><div class="service-item position-relative">
                                <img class="img-fluid" src="img1/service-4.jpg" alt="" />
                                <div class="service-text text-center">
                                    <h4 class="text-white font-weight-medium px-3">Skin Care</h4>
                                    <p class="text-white px-3 mb-3">Elitr labore sit dolor erat est lorem diam sea ipsum diam dolor duo sit ipsum</p>
                                    <div class="w-100 bg-white text-center p-4">
                                        <a class="btn btn-primary" href="">Make Order</a>
                                    </div>
                                </div>
                            </div></div><div class="owl-item cloned" style={{ width: "269.8px" }}><div class="service-item position-relative">
                                <img class="img-fluid" src="img1/service-5.jpg" alt="" />
                                <div class="service-text text-center">
                                    <h4 class="text-white font-weight-medium px-3">Stream Bath</h4>
                                    <p class="text-white px-3 mb-3">Elitr labore sit dolor erat est lorem diam sea ipsum diam dolor duo sit ipsum</p>
                                    <div class="w-100 bg-white text-center p-4">
                                        <a class="btn btn-primary" href="">Make Order</a>
                                    </div>
                                </div>
                            </div></div></div></div><div class="owl-nav disabled"><div class="owl-prev">prev</div><div class="owl-next">next</div></div><div class="owl-dots disabled"></div></div>

                <div class="row justify-content-center bg-appointment mx-0">
                    <div class="col-lg-6 py-5">
                        <div class="p-5 my-5" style={{ background: "rgba(33, 30, 28, 0.7)" }}>
                            <h1 class="text-white text-center mb-4">Make Appointment</h1>
                            <form>
                                <div class="form-row">
                                    <div class="col-sm-6">
                                        <div class="form-group">
                                            <input type="text" class="form-control bg-transparent p-4" placeholder="Your Name" required="required" />
                                        </div>
                                    </div>
                                    <div class="col-sm-6">
                                        <div class="form-group">
                                            <input type="email" class="form-control bg-transparent p-4" placeholder="Your Email" required="required" />
                                        </div>
                                    </div>
                                </div>
                                <div class="form-row">
                                    <div class="col-sm-6">
                                        <div class="form-group">
                                            <div class="date" id="date" data-target-input="nearest">
                                                <input type="text" class="form-control bg-transparent p-4 datetimepicker-input" placeholder="Select Date" data-target="#date" data-toggle="datetimepicker" />
                                            </div>
                                        </div>
                                    </div>
                                    <div class="col-sm-6">
                                        <div class="form-group">
                                            <div class="time" id="time" data-target-input="nearest">
                                                <input type="text" class="form-control bg-transparent p-4 datetimepicker-input" placeholder="Select Time" data-target="#time" data-toggle="datetimepicker" />
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div class="form-row">
                                    <div class="col-sm-6">
                                        <div class="form-group">
                                            <select class="custom-select bg-transparent px-4" style={{ height: "47px" }}>
                                                <option selected>Select A Service</option>
                                                <option value="1">Service 1</option>
                                                <option value="2">Service 1</option>
                                                <option value="3">Service 1</option>
                                            </select>
                                        </div>
                                    </div>
                                    <div class="col-sm-6">
                                        <button class="btn btn-primary btn-block" type="submit" style={{ height: "47px" }}>Make Appointment</button>
                                    </div>
                                </div>
                            </form>
                        </div>
                    </div>
                </div>
            </div> */}
    </>
  );
};

export default Yoga;
