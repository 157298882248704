
// // import React, { useState ,useEffect} from "react";


// // const Appointment = () => {
// //   useEffect(() => {
// //     window.scrollTo(0, 0); // Scrolls to the top of the page
// // }, []);

// //   const [status, setStatus] = useState("Submit");

// //   const [info, setInfo] = useState({ name: '', mobilenumber: '', email: '', service: '' });

// //   const handleChange = (event) => {
// //     setInfo({
// //       ...info,
// //       [event.target.name]: event.target.value
// //     })
// //   };
// //   const handleSubmit = async (e) => {
// //     e.preventDefault();
// //     console.log(info);
// //     setStatus("Sending...");


// //     let response = await fetch("/api/testiMonialspage", {
// //       method: "POST",
// //       headers: {
// //         'Access-Control-Allow-Origin': '*',
// //         'Accept': 'application/json',
// //         //'Content-Type': 'application/json',
// //         "Content-Type": "application/json;charset=utf-8",
// //       },
// //       body: JSON.stringify(info),
// //       //body: JSON.stringify(details),
// //     });
// //     //console.log(details);
// //     console.log(info);
// //     setStatus("Book an Appointment");
// //     setInfo({ name: '', mobilenumber: '', email: '', service: '' });
// //     let result = await response.json();
// //     //alert(result.status);
// //   };

// //   return (
// //     <>




// //       <div class="row justify-content-center bg-appointment mx-0 wow fadeInUp" data-wow-delay="0.5s" >
// //         <div class="col-lg-6 py-5" >
// //           <div class="p-4" style={{ background: "rgba(33, 30, 28, 0.7)" }}>
// //             <h1 class="text-white text-center mb-2">Make Appointment</h1>

// //             <form className='col-sm-10 mx-auto ' onSubmit={handleSubmit}  >

// //               <div class="form-group " style={{ backgroundColor: "white" }}>
// //                 <input type="text" width='100px' class="form-control bg-transparent p-4" name="name" pattern="[A-Za-z]+" id="name" placeholder="Your Name" required="required" value={info.name} onChange={handleChange} />
// //               </div>

// //               <div class="form-group" style={{ backgroundColor: "white" }}>
// //                 <input type="email" class="form-control bg-transparent p-4" name="email" id="email" placeholder="Your Email" required="required" value={info.email} onChange={handleChange} />
// //               </div>
// //               <div class="form-group" style={{ backgroundColor: "white" }}>
// //                 <input
// //                   type="tel" // Use "tel" type for mobile numbers
// //                   className="form-control bg-transparent p-4"
// //                   name="mobilenumber"
// //                   id="mobilenumber"
// //                   pattern="[0-9]{10}"
// //                   placeholder="Your Mobile Number"
// //                   value={info.mobilenumber}
// //                   onChange={handleChange}
// //                 />
// //                 <p className="help-block text-danger"></p>
// //               </div>
// //               {/* <div class="form-group" style={{ backgroundColor: "white" }}>
// //               <input type="date" class="form-control bg-transparent p-4" name="date" id="date" placeholder="Appointment" value={info.date} onChange={handleChange} />
// //             </div> */}
// //               <div class="form-row">
// //                 <div class="col-sm-12">
// //                   <div class="form-group" onSubmit={handleSubmit} style={{ backgroundColor: "white" }}>
// //                     <select class="custom-select  bg-transparent px-4" name="service" id="service" style={{ height: "47px", color: "gray" }} value={info.service} onChange={handleChange}>
// //                       <option selected >Select A Service</option>
// //                       <option value="Consultation" >Consultation</option>
// //                       <option value="Healthy Lifestyle">Healthy Lifestyle</option>
// //                       <option value="Diet and Food Therapy">Diet and Food Therapy</option>
// //                       <option value="Counseling">Counseling</option>
// //                       <option value="Accupenture">Acupuncture</option>
// //                       <option value="Training on Yoga">Training on Yoga</option>
// //                       <option value="Seminars to corporate">Seminars to Corporate</option>
// //                       <option value="Stress Deletion">Stress Deletion</option>
// //                     </select>
// //                   </div>
// //                 </div>
// //                 <div class="col-sm-12">
// //                   <button class="btn btn-primary btn-block" type="submit" style={{ height: "47px", color: "white" }}>Book an Appointment </button>
// //                 </div>
// //               </div>

// //             </form>

// //           </div>
// //         </div>
// //       </div>





// //     </>
// //   )
// // }

// // export default Appointment




// import React, { useState, useEffect } from "react";

// const Appointment = () => {
//   useEffect(() => {
//     window.scrollTo(0, 0); // Scrolls to the top of the page
//   }, []);

//   const [status, setStatus] = useState("Submit");
//   const [info, setInfo] = useState({ name: '', mobilenumber: '', email: '', service: '' });

//   const handleChange = (event) => {
//     setInfo({
//       ...info,
//       [event.target.name]: event.target.value
//     })
//   };

//   const handleSubmit = async (e) => {
//     e.preventDefault();
//     console.log(info);
//     setStatus("Sending...");

//     let response = await fetch("/api/testiMonialspage", {
//       method: "POST",
//       headers: {
//         'Access-Control-Allow-Origin': '*',
//         'Accept': 'application/json',
//         'Content-Type': 'application/json;charset=utf-8',
//       },
//       body: JSON.stringify(info),
//     });

//     console.log(info);
//     setStatus("Book an Appointment");
//     setInfo({ name: '', mobilenumber: '', email: '', service: '' });
//     let result = await response.json();
//   };

//   return (
//     <div className="row justify-content-center bg-appointment mx-0 wow fadeInUp" data-wow-delay="0.5s">
//       <div className="col-lg-6 py-5">
//         <div className="p-4" style={{ background: "rgba(33, 30, 28, 0.7)" }}>
//           <h1 className="text-white text-center mb-2">Make Appointment</h1>
//           <form className='col-sm-10 mx-auto' onSubmit={handleSubmit}>
//           <div className="form-row">
//             <div className="form-group" style={{ backgroundColor: "white" }}>
//               <input
//                 type="text"
//                 width='100px'
//                 className="form-control bg-transparent p-4"
//                 name="name"
//                 pattern="[A-Za-z]+"
//                 id="name"
//                 placeholder="Your First Name"
//                 required="required"
//                 value={info.name}
//                 onChange={handleChange}
//               />
//             </div>
        
//               <div className="form-group col-md-6" style={{ backgroundColor: "white" }}>
//             <input
//                 type="text"
//                 width='100px'
//                 className="form-control bg-transparent p-4"
//                 name="name"
//                 pattern="[A-Za-z]+"
//                 id="name"
//                 placeholder="Your  Second Name"
//                 required="required"
//                 value={info.name}
//                 onChange={handleChange}
//               />
//            </div>
//             </div>
//             <div className="form-row">
//               <div className="form-group col-md-6" style={{ backgroundColor: "white" }}>
//                 <input
//                   type="email"
//                   className="form-control bg-transparent p-4"
//                   name="email"
//                   id="email"
//                   placeholder="Your Email"
//                   required="required"
//                   value={info.email}
//                   onChange={handleChange}
//                 />
//               </div>
//               <div className="form-group col-md-6" style={{ backgroundColor: "white" }}>
//                 <input
//                   type="tel"
//                   className="form-control bg-transparent p-4"
//                   name="mobilenumber"
//                   id="mobilenumber"
//                   pattern="[0-9]{10}"
//                   placeholder="Your Mobile Number"
//                   value={info.mobilenumber}
//                   onChange={handleChange}
//                 />
//               </div>
//             </div>
//             <div className="form-row">
//               <div className="form-group col-md-6" style={{ backgroundColor: "white" }}>
//                 <input
//                   type="email"
//                   className="form-control bg-transparent p-4"
//                   name="email"
//                   id="email"
//                   placeholder="Age"
//                   required="required"
//                   value={info.email}
//                   onChange={handleChange}
//                 />
//               </div>
//               <div className="form-group" onSubmit={handleSubmit} style={{ backgroundColor: "white" }}>
//                   <select
//                     className="custom-select bg-transparent px-4"
//                     name="service"
//                     id="service"
//                     style={{ height: "47px", color: "gray" }}
//                     value={info.service}
//                     onChange={handleChange}
//                   >
//                     <option selected>Select A Service</option>
//                     <option value="Consultation">Female</option>
//                     <option value="Healthy Lifestyle">Male</option>
//                     <option value="Diet and Food Therapy">Others</option>
                    
//                   </select>
//                 </div>
//             </div>
//             <div className="form-row">
//               <div className="col-sm-12">
//                 {/* <div className="form-group" onSubmit={handleSubmit} style={{ backgroundColor: "white" }}>
//                   <select
//                     className="custom-select bg-transparent px-4"
//                     name="service"
//                     id="service"
//                     style={{ height: "47px", color: "gray" }}
//                     value={info.service}
//                     onChange={handleChange}
//                   >
//                     <option selected>Select A Service</option>
//                     <option value="Consultation">Consultation</option>
//                     <option value="Healthy Lifestyle">Healthy Lifestyle</option>
//                     <option value="Diet and Food Therapy">Diet and Food Therapy</option>
//                     <option value="Counseling">Counseling</option>
//                     <option value="Acupuncture">Acupuncture</option>
//                     <option value="Training on Yoga">Training on Yoga</option>
//                     <option value="Seminars to Corporate">Seminars to Corporate</option>
//                     <option value="Stress Deletion">Stress Deletion</option>
//                   </select>
//                 </div> */}

//                 <textarea placeholder="Write your complaints here"></textarea>
//               </div>
//               <div className="col-sm-12">
//                 <button className="btn btn-primary btn-block" type="submit" style={{ height: "47px", color: "white" }}>
//                   {status}
//                 </button>
//               </div>
//             </div>
//           </form>
//         </div>
//       </div>
//     </div>
//   );
// };

// export default Appointment;



import React, { useState, useEffect } from "react";
import './Appointment.css';  // Assuming your CSS is in a file named App.css

const Appointment = () => {
  useEffect(() => {
    window.scrollTo(0, 0); // Scrolls to the top of the page
  }, []);

  const [status, setStatus] = useState("Submit");
  const [info, setInfo] = useState({ firstName: '', secondName: '', mobilenumber: '', email: '', service: '', gender: '', age: '', complaints: '' });

  const handleChange = (event) => {
    setInfo({
      ...info,
      [event.target.name]: event.target.value
    });
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    console.log(info);
    setStatus("Sending...");

    let response = await fetch("/api/testiMonialspage", {
      method: "POST",
      headers: {
        'Access-Control-Allow-Origin': '*',
        'Accept': 'application/json',
        'Content-Type': 'application/json;charset=utf-8',
      },
      body: JSON.stringify(info),
    });

    console.log(info);
    setStatus("Book an Appointment");
    setInfo({ firstName: '', secondName: '', mobilenumber: '', email: '', service: '', gender: '', age: '', complaints: '' });
    let result = await response.json();
  };

  return (
    <div className="row justify-content-center bg-appointment mx-0 wow fadeInUp" data-wow-delay="0.5s">
      <div className="col-lg-6 py-5">
        <div className="p-4" style={{ background: "rgba(33, 30, 28, 0.7)" }}>
          <h1 className="text-white text-center mb-2" style={{marginTop:'40px'}}>Make Appointment</h1>
          <form className='col-sm-10 mx-auto' onSubmit={handleSubmit}>
            <div className="form-row position-relative">
              <div className="form-group col-md-6" style={{ backgroundColor: "white" }}>
                <input
                  type="text"
                  className="form-control bg-transparent p-4"
                  name="firstName"
                  pattern="[A-Za-z]+"
                  id="firstName"
                  placeholder=" First Name"
                  required="required"
                  value={info.firstName}
                  onChange={handleChange}
                />
              </div>
              <div className="divider-vertical"></div>
              <div className="form-group col-md-6" style={{ backgroundColor: "white" }}>
                <input
                  type="text"
                  className="form-control bg-transparent p-4"
                  name="secondName"
                  pattern="[A-Za-z]+"
                  id="secondName"
                  placeholder=" Second Name"
                  required="required"
                  value={info.secondName}
                  onChange={handleChange}
                />
              </div>
            </div>
            <div className="form-row position-relative">
              <div className="form-group col-md-6" style={{ backgroundColor: "white" }}>
                <input
                  type="email"
                  className="form-control bg-transparent p-4"
                  name="email"
                  id="email"
                  placeholder=" Email"
                  required="required"
                  value={info.email}
                  onChange={handleChange}
                />
              </div>
              <div className="divider-vertical"></div>
              <div className="form-group col-md-6" style={{ backgroundColor: "white" }}>
                <input
                  type="tel"
                  className="form-control bg-transparent p-4"
                  name="mobilenumber"
                  id="mobilenumber"
                  pattern="[0-9]{10}"
                  placeholder="Phone"
                  value={info.mobilenumber}
                  onChange={handleChange}
                />
              </div>
            </div>
            <div className="form-row position-relative">
              <div className="form-group col-md-6" style={{ backgroundColor: "white" }}>
                <input
                  type="number"
                  className="form-control bg-transparent p-4"
                  name="age"
                  id="age"
                  placeholder="Age"
                  required="required"
                  value={info.age}
                  onChange={handleChange}
                />
              </div>
              <div className="divider-vertical"></div>
              <div className="form-group col-md-6" style={{ backgroundColor: "white" }}>
                <select
                  className="custom-select bg-transparent px-4"
                  name="gender"
                  id="gender"
                  style={{ height: "47px", color: "gray" }}
                  value={info.gender}
                  onChange={handleChange}
                >
                  <option selected>Select Gender</option>
                  <option value="Female">Female</option>
                  <option value="Male">Male</option>
                  <option value="Others">Others</option>
                </select>
              </div>
            </div>
            <div className="form-group" style={{ backgroundColor: "white" }}>
              <select
                className="custom-select bg-transparent px-4"
                name="service"
                id="service"
                style={{ height: "47px", color: "gray" }}
                value={info.service}
                onChange={handleChange}
              >
                <option selected>Select A Service</option>
                <option value="Consultation">Consultation</option>
                <option value="Healthy Lifestyle">Healthy Lifestyle</option>
                <option value="Diet and Food Therapy">Diet and Food Therapy</option>
                <option value="Counseling">Counseling</option>
                <option value="Acupuncture">Acupuncture</option>
                <option value="Training on Yoga">Training on Yoga</option>
                <option value="Seminars to Corporate">Seminars to Corporate</option>
                <option value="Stress Deletion">Stress Deletion</option>
              </select>
            </div>
            <div className="form-group" style={{ backgroundColor: "white" }}>
              <textarea
                className="form-control bg-transparent p-4"
                name="complaints"
                id="complaints"
                placeholder="Write your complaints here"
                value={info.complaints}
                onChange={handleChange}
              ></textarea>
            </div>
            <div className="form-row">
              <div className="col-sm-12">
                <button className="btn btn-primary btn-block" type="submit" style={{ height: "47px", color: "white" }}>
                  {status}
                </button>
              </div>
            </div>
          </form>
        </div>
      </div>
    </div>
  );
};

export default Appointment;
